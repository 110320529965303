import React from "react";
import "../../components/styles/user/Faq.css";
import { Link } from "react-scroll";
import logo from "../../main_folder/assests/photos/seaboss_logo.png";
import { HeaderNavigation } from "../../components/user/Header_Navigation";
import { useState } from "react";
import { ScrolltoTopSection } from "../../components/user/Scroll_to_Top_Section";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

export const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const Question = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const faqs = [
    {
      question: "What types of fish do you have available?",
      answer:
        "We offer a wide variety of fresh fish, including salmon, tilapia, cod, haddock, trout, mackerel, tuna, snapper, and many more. Availability can vary based on the season and our supplier, so it's always best to check with us for the most current selection.",
    },
    {
      question: "Are the fish fresh or frozen?",
      answer:
        "We ship to most countries worldwide. Please check our shipping policy for more details.",
    },
    {
      question: "Do you sell any other seafood besides fish?",
      answer:
        "Yes, we offer a variety of seafood, including shrimp, crab, lobster, scallops, clams, mussels, squid, and more. Like our fish selection, the availability of other seafood items can depend on the season and supplier.",
    },
    {
      question:
        "Can I request specific cuts or have fish cleaned and filleted?",
      answer:
        "Absolutely! Our skilled staff can clean, fillet, or cut the fish to your specifications. Just let us know what you need, and we will prepare it for you.",
    },
    {
      question: "What is the price range for your fish?",
      answer:
        "Prices vary depending on the type of fish, its availability, and market conditions. Typically, common fish like tilapia and catfish are more affordable, while premium options like wild-caught salmon, tuna, or lobster may be more expensive. We offer competitive pricing to ensure quality seafood is accessible to all our customers.",
    },
    {
      question:
        "Can I place a bulk order or special request for a specific event?",
      answer:
        "Yes, we accept bulk orders and can accommodate special requests for events. Please contact us in advance to discuss your needs, and we'll do our best to meet them.",
    },
    {
      question: "What are the health benefits of eating fish?",
      answer:
        "Fish is a great source of high-quality protein, omega-3 fatty acids, vitamins, and minerals. Regular consumption of fish can support heart health, brain function, and overall wellness. Different types of fish have different nutritional benefits, so it’s good to include a variety in your diet.",
    },
    {
      question: "Can you recommend a fish that's good for grilling?",
      answer:
        "Sure! Fish like salmon, tuna, swordfish, and mahi-mahi are excellent choices for grilling due to their firm texture and rich flavor. They hold up well on the grill and are easy to cook.",
    },
    {
      question: "What measures do you take to ensure food safety and quality?",
      answer:
        "We adhere to strict food safety standards, including maintaining proper temperature controls, following rigorous cleaning protocols, and regularly inspecting our products for freshness and quality. Our staff is trained to handle seafood safely to ensure you receive the best products.",
    },
  ];

  // Footer Section

  const handleInsta = () => {
    window.open("https://www.instagram.com/seabossfishmarts/", "_blank");
  };

  const handleFb = () => {
    window.open(
      "https://www.facebook.com/people/Seaboss-Fishmart/pfbid02QKQk2Sga5oFDF2BrUviSP9gguEJbq33Ub4za4oWneu9HzwEYByjYLgck5dEppVgdl/?mibextid=ZbWKwL",
      "_blank"
    );
  };

  const handleYoutube = () => {
    window.open("https://www.youtube.com/@Seabossfishmart", "_blank");
  };

  // BreadCrumbs

  const headerData = {
    headerTitle: "Frequently Asked Questions",
    defaultPage: "Home",
    currentPage: "Faq",
  };
  
  return (
    <div>
      {/* Header Section */}
      <section id="header">
        <header className="header_main">
          <div className="header_overall">
            <div>
              <Link to="/">
                <img className="header_log" alt="fishing" src={logo}></img>
              </Link>
            </div>
            <div className="header_links_content">
              <div>
                <Link
                  className="header_link_name"
                  to={"/"}
                  smooth={true}
                  duration={500}
                >
                  Home
                </Link>
              </div>
              <div>
                <Link
                  className="header_link_name"
                  to={"benefits"}
                  smooth={true}
                  duration={500}
                >
                  Benefits
                </Link>
              </div>
              <div>
                <Link
                  className="header_link_name"
                  to={"hotDeals"}
                  smooth={true}
                  duration={500}
                >
                  Hot Deals
                </Link>
              </div>
              <div>
                <Link
                  className="header_link_name"
                  to={"recommends"}
                  smooth={true}
                  duration={500}
                >
                  Recommends
                </Link>
              </div>
              <div>
                <Link
                  className="header_link_name"
                  to={"allProducts"}
                  smooth={true}
                  duration={500}
                >
                  All Products
                </Link>
              </div>
            </div>
            <div className="header_btn_div">
              <Link to={"contactUs"} smooth={true} duration={500}>
                <button className="header_btn">CONTACT US</button>
              </Link>
              <div className="menu_bar_mobile">
                <span
                  id="menu_bar_span"
                  class="btn"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExampleSideBar"
                  aria-controls="offcanvasExample"
                >
                  <i
                    id="menu_bar_mobile_icon"
                    class="fa-solid fa-bars fa-xl"
                  ></i>
                </span>
              </div>
              <div
                class="offcanvas offcanvas-start"
                tabindex="-1"
                id="offcanvasExampleSideBar"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div class="offcanvas-header" id="sidebar_border">
                  <img className="header_log" alt="fishing" src={logo}></img>
                  <button
                    id="offcanvas_sidebar_close_btn"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <i id="sidebar_close_btn" class="fa-solid fa-xmark"></i>
                  </button>
                </div>
                <div class="offcanvas-body" id="sidebar_body">
                  <div>
                    <div
                      className="header_link_name_sidebar_div"
                      style={{ paddingTop: "0px" }}
                    >
                      <Link
                        to={"/"}
                        smooth={true}
                        duration={500}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <button className="header_link_name_sidebar">
                          Home
                        </button>
                      </Link>
                    </div>
                    <div className="header_link_name_sidebar_div">
                      <Link
                        to={"benefits"}
                        smooth={true}
                        duration={500}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <button className="header_link_name_sidebar">
                          Benefits
                        </button>
                      </Link>
                    </div>
                    <div className="header_link_name_sidebar_div">
                      <Link
                        to={"hotDeals"}
                        smooth={true}
                        duration={500}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <button className="header_link_name_sidebar">
                          Hot Deals
                        </button>
                      </Link>
                    </div>
                    <div className="header_link_name_sidebar_div">
                      <Link
                        to={"recommends"}
                        smooth={true}
                        duration={500}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <button className="header_link_name_sidebar">
                          Recommends
                        </button>
                      </Link>
                    </div>
                    <div className="header_link_name_sidebar_div">
                      <Link
                        to={"allProducts"}
                        smooth={true}
                        duration={500}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <button className="header_link_name_sidebar">
                          All Products
                        </button>
                      </Link>
                    </div>
                    <div className="header_link_name_sidebar_div">
                      <Link
                        to={"contactUs"}
                        smooth={true}
                        duration={500}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <button className="header_link_name_sidebar">
                          Contact Us
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="copyright_sidebar_mobile">
                  <div className="copyright_sidebar_first">
                    © 2024 Seaboss Fish Mart, All Rights Reserved.
                  </div>
                  <div className="copyright_sidebar_second">
                    Designed By Seaboss
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </section>

      {/* BreadCrumbs */}
      <HeaderNavigation data={headerData} />

      {/* Faq Section */}

      <div className="faq_container">
        <h2 className="faq_title">Popular Questions</h2>
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq_item ${activeIndex === index ? "active" : ""}`}
            onClick={() => Question(index)}
          >
            <div className="faq_question">
              {faq.question}
              <span className="faq_icon">
                {activeIndex === index ? (
                  <FontAwesomeIcon icon={faMinus} />
                ) : (
                  <FontAwesomeIcon icon={faPlus} />
                )}
              </span>
            </div>
            <div
              className="faq_answer"
              style={{
                maxHeight: activeIndex === index ? "200px" : "0",
              }}
            >
              {faq.answer}
            </div>
          </div>
        ))}
      </div>

      {/* Footer Section */}
      <section>
        <div className="footer_center">
          <div>
            <div className="footer_flex">
              <div>
                <div className="footer_title">
                  <h5 className="footer_title_content">ABOUT US</h5>
                </div>
                <div id="footer_innerPad">
                  <div>
                    <p className="footer_aboutUs">
                      {" "}
                      SeaBoss Fishmart is a leading seafood company dedicated to
                      providing customers with the freshest and highest quality
                      fish and seafood products. With a commitment to sourcing
                      sustainably and responsibly, SeaBoss Fishmart offers a
                      diverse selection of seafood sourced from trusted
                      suppliers and local fishermen.
                    </p>
                  </div>
                  <div className="footer_social_links_overall">
                    <div>
                      <button onClick={handleFb} className="footer_social_link">
                        <i
                          id="footer_social_link_icon"
                          class="fa-brands fa-facebook-f"
                        ></i>
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={handleInsta}
                        className="footer_social_link"
                        id="footer_social_link"
                      >
                        <i
                          id="footer_social_link_icon"
                          class="fa-brands fa-instagram"
                        ></i>
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={handleYoutube}
                        className="footer_social_link"
                        id="footer_social_link"
                      >
                        <i
                          id="footer_social_link_icon"
                          class="fa-brands fa-youtube"
                        ></i>
                      </button>
                    </div>
                    {/* <div>
                                                        <button className="footer_social_link" id="footer_social_link">
                                                            <i id="footer_social_link_icon" class="fa-brands fa-x-twitter"></i>
                                                        </button>
                                                    </div> */}
                  </div>
                </div>
                <div>
                  <hr className="footer_seperate_mobile"></hr>
                </div>
              </div>
              <div>
                <div className="footer_title">
                  <h5 className="footer_title_content">QUICK LINKS</h5>
                </div>
                <div className="footer_quick_linksDiv" id="footer_innerPad">
                  <div>
                    <Link
                      className="footer_quick_links"
                      to={"header"}
                      smooth={true}
                      duration={500}
                    >
                      Home
                    </Link>
                  </div>
                  <div>
                    <Link
                      className="footer_quick_links"
                      to={"benefits"}
                      smooth={true}
                      duration={500}
                    >
                      Benefits
                    </Link>
                  </div>
                  <div>
                    <Link
                      className="footer_quick_links"
                      to={"hotDeals"}
                      smooth={true}
                      duration={500}
                    >
                      Hot Deals
                    </Link>
                  </div>
                  <div>
                    <Link
                      className="footer_quick_links"
                      to={"recommends"}
                      smooth={true}
                      duration={500}
                    >
                      Recommends
                    </Link>
                  </div>
                  <div>
                    <Link
                      className="footer_quick_links"
                      to={"allProducts"}
                      smooth={true}
                      duration={500}
                    >
                      All Products
                    </Link>
                  </div>
                  <div>
                    <Link className="footer_quick_links" to={"/contact"}>
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div>
                  <hr className="footer_seperate_mobile"></hr>
                </div>
              </div>
              <div>
                <div className="footer_title">
                  <h5 className="footer_title_content">ADDRESS</h5>
                </div>
                <div id="footer_innerPad">
                  <div>
                    <div>
                      <div>
                        <address className="footer_addressContent">
                          NO.380/9Th, Main 23RD Cross 7Th Sector, HSR Layout,
                          Bangalore-560102.
                        </address>
                      </div>
                    </div>
                    <div>
                      <hr className="footer_seperate"></hr>
                    </div>
                    <div className="footer_phoneNumber_overall">
                      <div>
                        <h6 className="footer_title_content">PHONE NUMBER</h6>
                      </div>
                      <div className="footer_phone_numDiv">
                        <h6 className="footer_phone_num">+91 8867906289</h6>
                        <h6 className="footer_phone_num">+91 8867906290</h6>
                      </div>
                    </div>
                    <div>
                      <hr className="footer_seperate"></hr>
                    </div>
                    <div className="footer_email_content">
                      <h6 className="footer_title_content">EMAIL</h6>
                      <div>
                        <span>franchisee@seaboss.in</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <hr className="footer_seperate_mobile"></hr>
                </div>
              </div>
              <div>
                <div className="footer_title">
                  <h5 className="footer_title_content">MAP</h5>
                </div>
                <div id="footer_innerPad" className="footer_map_container">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.0082629604753!2d77.63359788656128!3d12.907190020324972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae155c2efbe7fd%3A0xe89890424ad16129!2sSeaboss%20Fish%20mart!5e0!3m2!1sen!2sin!4v1719053557199!5m2!1sen!2sin"
                    className="mapStyles"
                    allowfullscreen=""
                    loading="lazy"
                    title="seaboss_location"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>

            <div className="footer_hr_line"></div>

            <div className="footer_copyright">
              <h6 className="footer_copyright_content">
                Copyright © Seaboss FishMart 2024 . ALL Right Reserved .
              </h6>
            </div>
          </div>
        </div>
      </section>

      {/* Scroll to Top Section */}
      <ScrolltoTopSection />
    </div>
  );
};
