import React from "react";
import logo from "../../main_folder/assests/photos/seaboss_logo.png";
import "../../components/styles/user/contactUs.css";
// import contactimg from "../../assests/user/images/contact_us_img/fishmart2.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

// import { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { HeaderNavigation } from "../../components/user/Header_Navigation";
import { ScrolltoTopSection } from "../../components/user/Scroll_to_Top_Section";
export const Contact = () => {
  // Footer Section

  const handleInsta = () => {
    window.open("https://www.instagram.com/seabossfishmarts/", "_blank");
  };

  const handleFb = () => {
    window.open(
      "https://www.facebook.com/people/Seaboss-Fishmart/pfbid02QKQk2Sga5oFDF2BrUviSP9gguEJbq33Ub4za4oWneu9HzwEYByjYLgck5dEppVgdl/?mibextid=ZbWKwL",
      "_blank"
    );
  };

  const handleYoutube = () => {
    window.open("https://www.youtube.com/@Seabossfishmart", "_blank");
  };

  const handleEmail = () => {
    window.open("mailto:franchisee@seaboss.in", "_blank");
  };

  const handleDialer = () => {
    window.open("tel:+918867906289", "_blank");
  };

  const headerData = {
    headerTitle: "Contact Us",
    defaultPage: "Home",
    currentPage: "Contact Us",
  };
  return (
    <div>
      {/* Header Section */}
      <section id="header">
        <header className="header_main">
          <div className="header_overall">
            <div>
              <Link to="/">
                <img className="header_log" alt="fishing" src={logo}></img>
              </Link>
            </div>
            <div className="header_links_content">
              <div>
                <Link
                  className="header_link_name"
                  to={"/"}
                  smooth={true}
                  duration={500}
                >
                  Home
                </Link>
              </div>
              <div>
                <Link
                  className="header_link_name"
                  to={"benefits"}
                  smooth={true}
                  duration={500}
                >
                  Benefits
                </Link>
              </div>
              <div>
                <Link
                  className="header_link_name"
                  to={"hotDeals"}
                  smooth={true}
                  duration={500}
                >
                  Hot Deals
                </Link>
              </div>
              <div>
                <Link
                  className="header_link_name"
                  to={"recommends"}
                  smooth={true}
                  duration={500}
                >
                  Recommends
                </Link>
              </div>
              <div>
                <Link
                  className="header_link_name"
                  to={"allProducts"}
                  smooth={true}
                  duration={500}
                >
                  All Products
                </Link>
              </div>
            </div>
            <div className="header_btn_div">
              <Link to={"contactUs"} smooth={true} duration={500}>
                <button className="header_btn">CONTACT US</button>
              </Link>
              <div className="menu_bar_mobile">
                <span
                  id="menu_bar_span"
                  class="btn"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExampleSideBar"
                  aria-controls="offcanvasExample"
                >
                  <i
                    id="menu_bar_mobile_icon"
                    class="fa-solid fa-bars fa-xl"
                  ></i>
                </span>
              </div>
              <div
                class="offcanvas offcanvas-start"
                tabindex="-1"
                id="offcanvasExampleSideBar"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div class="offcanvas-header" id="sidebar_border">
                  <img className="header_log" alt="fishing" src={logo}></img>
                  <button
                    id="offcanvas_sidebar_close_btn"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <i id="sidebar_close_btn" class="fa-solid fa-xmark"></i>
                  </button>
                </div>
                <div class="offcanvas-body" id="sidebar_body">
                  <div>
                    <div
                      className="header_link_name_sidebar_div"
                      style={{ paddingTop: "0px" }}
                    >
                      <Link
                        to={"/"}
                        smooth={true}
                        duration={500}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <button className="header_link_name_sidebar">
                          Home
                        </button>
                      </Link>
                    </div>
                    <div className="header_link_name_sidebar_div">
                      <Link
                        to={"benefits"}
                        smooth={true}
                        duration={500}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <button className="header_link_name_sidebar">
                          Benefits
                        </button>
                      </Link>
                    </div>
                    <div className="header_link_name_sidebar_div">
                      <Link
                        to={"hotDeals"}
                        smooth={true}
                        duration={500}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <button className="header_link_name_sidebar">
                          Hot Deals
                        </button>
                      </Link>
                    </div>
                    <div className="header_link_name_sidebar_div">
                      <Link
                        to={"recommends"}
                        smooth={true}
                        duration={500}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <button className="header_link_name_sidebar">
                          Recommends
                        </button>
                      </Link>
                    </div>
                    <div className="header_link_name_sidebar_div">
                      <Link
                        to={"allProducts"}
                        smooth={true}
                        duration={500}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <button className="header_link_name_sidebar">
                          All Products
                        </button>
                      </Link>
                    </div>
                    <div className="header_link_name_sidebar_div">
                      <Link
                        to={"contactUs"}
                        smooth={true}
                        duration={500}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <button className="header_link_name_sidebar">
                          Contact Us
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="copyright_sidebar_mobile">
                  <div className="copyright_sidebar_first">
                    © 2024 Seaboss Fish Mart, All Rights Reserved.
                  </div>
                  <div className="copyright_sidebar_second">
                    Designed By Seaboss
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </section>

      {/* contact us Section */}

      {/* BreadCrumbs */}
      <HeaderNavigation data={headerData} />

      {/* Contact Us main */}
      <section id="contact_us">
        <div className="contact_us_image">
          {/* <img src={""} alt="" />
          <div className="contact_us_header">
            <h4>Contact Us</h4>
            <h2>We Are Available All Time</h2>
          </div> */}
        </div>
        <div className="contact_us_form_page">
          <div className="contact_us_form_page_div">
            <div className="con">
              <div className="contact_us_info">
                <div className="contact_us_info_group">
                  <input
                    className="contact_us_info_group_input"
                    type="text"
                    placeholder="Name"
                  />
                </div>
                <div className="contact_us_info_group">
                  <input
                    className="contact_us_info_group_input"
                    type="text"
                    placeholder="Email"
                  />
                </div>
                <div className="contact_us_info_group">
                  <textarea
                    className="contact_us_info_group_textarea"
                    name="comment"
                    placeholder="Comment"
                  ></textarea>
                </div>
                <div className="contact_us_info_button">
                  <button className="contact_us_info_button_btn">SEND</button>
                </div>
              </div>
            </div>
          </div>
          <div className="contact_us_para">
            <div className="contact_us_para_heading">
              <h6 className="contact_us_para_h6">Contact Us</h6>
              <h3 className="contact_us_para_h3">Get in Touch</h3>
              <p className="contact_us_paragraph">
                Join the Seaboss Fishmart family as a franchisee and become a
                part of our mission to deliver the freshest seafood to
                communities. If you're interested in starting your own Seaboss
                Fishmart or have questions about the franchise process, our team
                is here to help. Contact us today to learn more and get started!
              </p>
            </div>
            <div className="contact_us_address">
              <div className="contact_us_address_item">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="contact_us_address_icon"
                />
                <a
                  href="https://www.google.com/maps?q=Seaboss+Fish+mart&output=embed"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact_us_address_item_p contact_us_item_p_address contact_us_item_email"
                >
                  380 9th Main,22nd Cross Rd,7th Sector,Bengaluru,Karnataka
                  560102
                </a>
              </div>
              <div className="contact_us_address_item" onClick={handleEmail}>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="contact_us_address_icon"
                />
                <p className="contact_us_address_item_p contact_us_item_email">
                  franchisee@seaboss.in
                </p>
              </div>
              <div className="contact_us_address_item" onClick={handleDialer}>
                <FontAwesomeIcon
                  icon={faPhone}
                  className="contact_us_address_icon"
                />
                <p className="contact_us_address_item_p contact_us_item_email">
                  +91 8867906289
                </p>
              </div>
            </div>
            <div className="contact_us_social_media">
              <h3 className="contact_us_social_media_head">
                Connect on Social
              </h3>
              <div className="contact_us_social_icons">
                <a
                  href="https://www.facebook.com/people/Seaboss-Fishmart/pfbid02QKQk2Sga5oFDF2BrUviSP9gguEJbq33Ub4za4oWneu9HzwEYByjYLgck5dEppVgdl/?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    className="contact_us_social_icon"
                  />
                </a>
                <a
                  href="https://www.instagram.com/seabossfishmarts/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="contact_us_social_icon"
                  />
                </a>
                <a
                  href="https://www.youtube.com/@Seabossfishmart"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    className="contact_us_social_icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="contact_us_map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.0082629604753!2d77.63359788656128!3d12.907190020324972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae155c2efbe7fd%3A0xe89890424ad16129!2sSeaboss%20Fish%20mart!5e0!3m2!1sen!2sin!4v1719053557199!5m2!1sen!2sin"
            className="mapStyles"
            allowfullscreen=""
            loading="lazy"
            title="seaboss_location"
            referrerpolicy="no-referrer-when-downgrade"
            style={{ border: 0 }}
          ></iframe>
        </div>
      </section>

      {/* Footer Section */}
      <section>
        <div className="footer_center">
          <div>
            <div className="footer_flex">
              <div>
                <div className="footer_title">
                  <h5 className="footer_title_content">ABOUT US</h5>
                </div>
                <div id="footer_innerPad">
                  <div>
                    <p className="footer_aboutUs">
                      {" "}
                      SeaBoss Fishmart is a leading seafood company dedicated to
                      providing customers with the freshest and highest quality
                      fish and seafood products. With a commitment to sourcing
                      sustainably and responsibly, SeaBoss Fishmart offers a
                      diverse selection of seafood sourced from trusted
                      suppliers and local fishermen.
                    </p>
                  </div>
                  <div className="footer_social_links_overall">
                    <div>
                      <button onClick={handleFb} className="footer_social_link">
                        <i
                          id="footer_social_link_icon"
                          class="fa-brands fa-facebook-f"
                        ></i>
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={handleInsta}
                        className="footer_social_link"
                        id="footer_social_link"
                      >
                        <i
                          id="footer_social_link_icon"
                          class="fa-brands fa-instagram"
                        ></i>
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={handleYoutube}
                        className="footer_social_link"
                        id="footer_social_link"
                      >
                        <i
                          id="footer_social_link_icon"
                          class="fa-brands fa-youtube"
                        ></i>
                      </button>
                    </div>
                    {/* <div>
                                                        <button className="footer_social_link" id="footer_social_link">
                                                            <i id="footer_social_link_icon" class="fa-brands fa-x-twitter"></i>
                                                        </button>
                                                    </div> */}
                  </div>
                </div>
                <div>
                  <hr className="footer_seperate_mobile"></hr>
                </div>
              </div>
              <div>
                <div className="footer_title">
                  <h5 className="footer_title_content">QUICK LINKS</h5>
                </div>
                <div className="footer_quick_linksDiv" id="footer_innerPad">
                  <div>
                    <Link
                      className="footer_quick_links"
                      to={"header"}
                      smooth={true}
                      duration={500}
                    >
                      Home
                    </Link>
                  </div>
                  <div>
                    <Link
                      className="footer_quick_links"
                      to={"benefits"}
                      smooth={true}
                      duration={500}
                    >
                      Benefits
                    </Link>
                  </div>
                  <div>
                    <Link
                      className="footer_quick_links"
                      to={"hotDeals"}
                      smooth={true}
                      duration={500}
                    >
                      Hot Deals
                    </Link>
                  </div>
                  <div>
                    <Link
                      className="footer_quick_links"
                      to={"recommends"}
                      smooth={true}
                      duration={500}
                    >
                      Recommends
                    </Link>
                  </div>
                  <div>
                    <Link
                      className="footer_quick_links"
                      to={"allProducts"}
                      smooth={true}
                      duration={500}
                    >
                      All Products
                    </Link>
                  </div>
                  <div>
                    <Link
                      className="footer_quick_links"
                      to={"contactUs"}
                      smooth={true}
                      duration={500}
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div>
                  <hr className="footer_seperate_mobile"></hr>
                </div>
              </div>
              <div>
                <div className="footer_title">
                  <h5 className="footer_title_content">ADDRESS</h5>
                </div>
                <div id="footer_innerPad">
                  <div>
                    <div>
                      <div>
                        <address className="footer_addressContent">
                          NO.380/9Th, Main 23RD Cross 7Th Sector, HSR Layout,
                          Bangalore-560102.
                        </address>
                      </div>
                    </div>
                    <div>
                      <hr className="footer_seperate"></hr>
                    </div>
                    <div className="footer_phoneNumber_overall">
                      <div>
                        <h6 className="footer_title_content">PHONE NUMBER</h6>
                      </div>
                      <div className="footer_phone_numDiv">
                        <h6 className="footer_phone_num">+91 8867906289</h6>
                        <h6 className="footer_phone_num">+91 8867906290</h6>
                      </div>
                    </div>
                    <div>
                      <hr className="footer_seperate"></hr>
                    </div>
                    <div className="footer_email_content">
                      <h6 className="footer_title_content">EMAIL</h6>
                      <div>
                        <span>franchisee@seaboss.in</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <hr className="footer_seperate_mobile"></hr>
                </div>
              </div>
              <div>
                <div className="footer_title">
                  <h5 className="footer_title_content">MAP</h5>
                </div>
                <div id="footer_innerPad" className="footer_map_container">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.0082629604753!2d77.63359788656128!3d12.907190020324972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae155c2efbe7fd%3A0xe89890424ad16129!2sSeaboss%20Fish%20mart!5e0!3m2!1sen!2sin!4v1719053557199!5m2!1sen!2sin"
                    className="mapStyles"
                    allowfullscreen=""
                    loading="lazy"
                    title="seaboss_location"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>

            <div className="footer_hr_line"></div>

            <div className="footer_copyright">
              <h6 className="footer_copyright_content">
                Copyright © Seaboss FishMart 2024 . ALL Right Reserved .
              </h6>
            </div>
          </div>
        </div>
      </section>

      {/* Scroll to Top Section */}

      <ScrolltoTopSection />
    </div>
  );
};
