import "../styles/user/products.css";

// SeaFish
import anchovy from "../../assests/user/images/seaFish/anchovy.png";
import bream from "../../assests/user/images/seaFish/Bream.jpg";
import croaker from "../../assests/user/images/seaFish/croaker.png";
import salmon from "../../assests/user/images/seaFish/salmon.jpg";
import mackerel from "../../assests/user/images/seaFish/mackerel.jpg";
import blackPomfret from "../../assests/user/images/seaFish/blackPomfret.jpg";
import herring from "../../assests/user/images/seaFish/herring.jpg";
import ribbon from "../../assests/user/images/seaFish/ribbon.jpg";
import tuna from "../../assests/user/images/seaFish/tuna.png";
import milk from "../../assests/user/images/seaFish/milk.png";

// Fresh Water Fish
import catla from "../../assests/user/images/freshWaterFish/catla.jpg";
import barramundi from "../../assests/user/images/freshWaterFish/barramundi.jpg";
import rohu from "../../assests/user/images/freshWaterFish/rohu.png";
import tilapia from "../../assests/user/images/freshWaterFish/tilapia.png";
import pilot from "../../assests/user/images/freshWaterFish/pilot.png";
import eel from "../../assests/user/images/freshWaterFish/eel.jpeg";
import whiteCarpFish from "../../assests/user/images/freshWaterFish/whiteCarpFish.png";
import catFish from "../../assests/user/images/freshWaterFish/catFish.jpg";

// Prawns fish
import atlantic from "../../assests/user/images/prawns/atlantic.jpg";
import blackTigerShrimp from "../../assests/user/images/prawns/blackTigerShrimp.jpg";
import northernPrawn from "../../assests/user/images/prawns/northernPrawn.jpg";
import whiteLegShrimp from "../../assests/user/images/prawns/whiteLegShrimp.jpg";
import giantTigerShrimp from "../../assests/user/images/prawns/giantTigerShrimp.jpg";

// Crab
import dungness from "../../assests/user/images/crab/dungness.jpeg";
import mud from "../../assests/user/images/crab/mud.jpg";
import blueCrab from "../../assests/user/images/crab/blueCrab.jpg";
import seaCrab from "../../assests/user/images/crab/seaCrab.jpg";
import stoneCrab from "../../assests/user/images/crab/stoneCrab.png";

// Squid
import arrow from "../../assests/user/images/squid/arrow.jpg";
import european from "../../assests/user/images/squid/european.jpg";
import giant from "../../assests/user/images/squid/giant.png";
import japaneseFlying from "../../assests/user/images/squid/japaneseFlyingSquid.jpg";
import jumbo from "../../assests/user/images/squid/jumbo.jpg";

// Pickles & Masala Items

import fishPickle from "../../assests/user/images/pickled_Masala/fishPickles.png";
import fishKebabMasala from "../../assests/user/images/pickled_Masala/fishKebabMasala.jpeg";
import fishKulambu from "../../assests/user/images/pickled_Masala/fishKulambuMasala.jpeg";
import fish_65 from "../../assests/user/images/pickled_Masala/Fish_65_Masala.jpg";

// Dried Fish
import driedAnchovy from "../../assests/user/images/driedFish/driedAnchovy.jpg";
import driedShankara from "../../assests/user/images/driedFish/driedShankaraFish.png";
import driedPrawn from "../../assests/user/images/driedFish/DriedPrawn.png";
import driedShell from "../../assests/user/images/driedFish/Driedshell.png";
import driedCroker from "../../assests/user/images/driedFish/driedCroakerFish.jpg";
import driedMackrelFish from "../../assests/user/images/driedFish/DriedMackrelFish.png";
import driedBombayDuckFish from "../../assests/user/images/driedFish/driedBombayDuckFish.png";
import driedShrimp from "../../assests/user/images/driedFish/driedShrimp.jpg";
import driedEel from "../../assests/user/images/driedFish/driedEelFish.png";


// React-slick
import React, { useState } from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from "react-scroll";

export const Products = ({ propsProductValue }) => {

    const whatsappNumber = "6366192865";

    const whatsappUrl = `https://wa.me/${whatsappNumber}`;

    const handleChatSubmit = (e) => {
        e.preventDefault();
        window.open(whatsappUrl, "_blank")
    }

    const kilo = " / kg";

    const gram = "- (50 g)";

    const gram100 = "- (100 g)";



    const productList = {
        seaFish: [
            {
                discount: "-34%",
                featured: false,
                img: anchovy,
                stock: "In Stock",
                productName: "Anchovy fish",
                mainAmount: 250.00,
                subAmount: 350.00,
                type: kilo,
                para: "Anchovies are small, green ﬁsh with blue reﬂections due to a silver-colored longitudinal stripe that runs from the base of the caudal (tail) ﬁn.They range from 2 to 40 centimeters(1 to 15+1⁄2 inches) in adult length, and their more slender ﬁsh in northern populations."
            },
            {
                discount: "-25%",
                featured: false,
                img: herring,
                stock: "In Stock",
                productName: "Herring fish",
                mainAmount: 200.00,
                subAmount: 250.00,
                type: kilo,
                para: "Herring have a blue-green upper body with silvery sides and are devoid of markings.The body is laterally compressed and the scales along the underside project in a slightly serrated arrangement.Scales are large and easily removed.Their tails are deeply forked and they lack adipose ﬁns."
            },
            {
                discount: "-14%",
                featured: true,
                img: croaker,
                stock: "In Stock",
                productName: "Croaker fish",
                mainAmount: 150.00,
                subAmount: 200.00,
                type: kilo,
                para: "The Atlantic croaker is a member of the drum and croaker family. It has a slightly elongated, fusiform body with a rounded dorsal proﬁle and nearly straight ventral proﬁle. It has a moderately large and sub terminal mouth, and there are between six and ten tiny chin barbells."
            },
            {
                discount: "-25%",
                featured: false,
                img: salmon,
                stock: "In Stock",
                productName: "Salmon fish",
                mainAmount: 400.00,
                subAmount: 600.00,
                type: kilo,
                para: "They're found in tributaries of the Paciﬁc and Atlantic Oceans, and most species are anadromous: They are born in streams and rivers, migrate out to the open sea, and then return to freshwater again to reproduce. Salmons have color throughout their lifetimes."
            },
            {
                discount: "-33%",
                featured: false,
                img: mackerel,
                stock: "In Stock",
                productName: "Mackerel fish",
                mainAmount: 150.00,
                subAmount: 250.00,
                type: kilo,
                para: "Mackerels are rounded and torpedo-shaped, with a slender, keeled tail base, a forked tail,and a row of small inlets behind the dorsal and anal ﬁns. They are carnivorous ﬁshes and feed on plankton, crustaceans, mollusks, ﬁsh eggs, and small ﬁsh."
            },
            {
                discount: "-14%",
                featured: true,
                img: blackPomfret,
                stock: "In Stock",
                productName: "Black Pomfret Fish",
                mainAmount: 600.00,
                subAmount: 800.00,
                type: kilo,
                para: "The black pomfret has a compressed deep body with dorsal and ventral proﬁles being equally convex.This species has small dark grey scales which cover the entirety of the body.The fork measurement of Black Pomfret is commonly 30 cm but can also range up to 75 cm in length."
            },
            {
                discount: "-50%",
                featured: false,
                img: bream,
                stock: "In Stock",
                productName: "Bream fish",
                mainAmount: 130.00,
                subAmount: 200.00,
                type: kilo,
                para: "The bream is a medium-sized ﬁsh and a member of the carp family, so displays the typical carp shape. It is a bottom-feeding ﬁsh that preys on worms, snails and pea mussels. It lives in large ponds, lakes and slow-moving rivers where it congregates in quite large shoals."
            },
            {
                discount: "-50%",
                featured: false,
                img: ribbon,
                stock: "In Stock",
                productName: "Ribbon fish",
                mainAmount: 150.00,
                subAmount: 200.00,
                type: kilo,
                para: "A ribbon-shaped ﬁsh, very thin from side to side, the oarﬁsh may grow to a length of about 9 meters (30.5 feet) and a weight of 300 kg (660 pounds).It is shiny silver in color, with long, red, oarlike rises as a manelike crest on top of the head."
            },
            {
                discount: "-14%",
                featured: true,
                img: tuna,
                stock: "In Stock",
                productName: "Tuna fish",
                mainAmount: 100.00,
                subAmount: 200.00,
                type: kilo,
                para: "Tuna are remarkable and impressive wild animals. The Atlantic blueﬁn can reach ten feet in length and weigh as much as 2,000 pounds (more than a horse).Their specialized body shape, ﬁns, and scales enable some species of tuna to swim as fast as 43 miles per hour."
            },
            {
                discount: "-25%",
                featured: false,
                img: milk,
                stock: "In Stock",
                productName: "Milk fish",
                mainAmount: 200.00,
                subAmount: 300.00,
                type: kilo,
                para: "Milkﬁsh have a spindle-like shape that is moderately compressed, smooth and streamlined. Its body color is silvery on the belly and sides, grading to olive green or blue on the back. The wild catch is limited to just slightly more than one percent of total world production."
            }
        ],
        freshWater: [
            {
                discount: "-34%",
                featured: false,
                img: barramundi,
                stock: "In Stock",
                productName: "Barramundi fish",
                mainAmount: 250.00,
                subAmount: 350.00,
                type: kilo,
                para: "Barramundi can be recognized by a distinct pointed head, concave forehead, large jaw extending behind the eye and a rounded tail ﬁn.They have a dorsal ﬁn with seven or eight strong spines and a second dorsal in often or eleven rays."
            },
            {
                discount: "-50%",
                featured: false,
                img: catla,
                stock: "In Stock",
                productName: "Catla fish",
                mainAmount: 100.00,
                subAmount: 150.00,
                type: kilo,
                para: "Catla is a ﬁsh with large and broad head, a large protruding lower jaw, and upturned mouth. It has large, greyish scales on its dorsal side and whitish on its belly. It reaches up to 182 cm (6.0 ft) in length and 38.6 kg (85 lb) in weight."
            },
            {
                discount: "-14%",
                featured: true,
                img: rohu,
                stock: "In Stock",
                productName: "Rohu",
                mainAmount: 100.00,
                subAmount: 150.00,
                type: kilo,
                para: "The rohu is a large, silver-colored ﬁsh of typical cyprinid shape, with a conspicuously arched head. Adults can reach a maximum weight of 45 kg (99 lb) and maximum length of 2 m (6.6 ft), but average around 1⁄2 m (1.6 ft)."
            },
            {
                discount: "-25%",
                featured: false,
                img: tilapia,
                stock: "In Stock",
                productName: "Tilapia fish",
                mainAmount: 100.00,
                subAmount: 150.00,
                type: kilo,
                para: "Tilapia are a hardy, fast growing ﬁsh, that can live up to ten years and reach ten pounds in weight. Tilapia are shaped like a sunﬁsh or crappie and are easily identiﬁable by the interrupted lateral line characteristic of the Chiclid family of ﬁshes."
            },
            {
                discount: "-14%",
                featured: true,
                img: pilot,
                stock: "In Stock",
                productName: "Pilot fish",
                mainAmount: 80.00,
                subAmount: 100.00,
                type: kilo,
                para: "The pilot ﬁsh is elongated and has a forked tail, a lengthwise keel on each side of the tail base, and a low ﬁrst dorsal ﬁn. It grows to a length of about 60 cm (2 feet) but is usually about 35 cm long. It is distinctively marked with ﬁve to seven vertical, dark bands on a bluish body."
            },
            {
                discount: "-14%",
                featured: true,
                img: eel,
                stock: "In Stock",
                productName: "Eel fish",
                mainAmount: 150.00,
                subAmount: 250.00,
                type: kilo,
                para: "An eel is distinguished externally from most other ﬁshes by its elongated body, which is seldom laterally compressed. A continuous dorsal, anal, and caudal ﬁn runs around the tail tip pelvic ﬁns are always absent; and gill openings are usually reduced."
            },
            {
                discount: "-14%",
                featured: true,
                img: whiteCarpFish,
                stock: "In Stock",
                productName: "White Carp fish",
                mainAmount: 100.00,
                subAmount: 150.00,
                type: kilo,
                para: "The mrigal carp (Cirrhinus cirrhosus), also known as the white carp, is a species of ray-ﬁnned ﬁsh in the carp family. Native to streams and rivers in India, the only surviving wild population is in the Cauvery River, leading to its IUCN rating as vulnerable."
            },
            {
                discount: "-14%",
                featured: true,
                img: catFish,
                stock: "In Stock",
                productName: "Cat fish",
                mainAmount: 100.00,
                subAmount: 150.00,
                type: kilo,
                para: "Catﬁsh are so-named because of their whisker- like barbels, which are located on the nose, each side of the mouth, and on the chin. Most catﬁsh possess leading spines in their dorsal and pectoral ﬁns. Catﬁsh are scaleless, a characteristic of from most other teleost ﬁsh."
            }
        ],
        prawn: [
            {
                discount: "-34%",
                featured: false,
                img: atlantic,
                stock: "In Stock",
                productName: "Atlantic prawn",
                mainAmount: 300.00,
                subAmount: 400.00,
                type: kilo,
                para: "The Atlantic white shrimp was the first commercially important shrimp in the United States. The prawn species' body is bluish-white in color with slightly pink sides and has tail flippers that are black near the base with green and yellow margins."
            },
            {
                discount: "-50%",
                featured: false,
                img: blackTigerShrimp,
                stock: "In Stock",
                productName: "Black Tiger Shrimp",
                mainAmount: 350.00,
                subAmount: 450.00,
                type: kilo,
                para: "The Black Tiger Shrimp's natural range is vast, from the east coast of Africa, throughout the Indian Ocean, and in South Asia. Black Tiger Shrimp are named for their large size and striped tails and can reach a length of over a foot in some cases."
            },
            {
                discount: "-14%",
                featured: true,
                img: northernPrawn,
                stock: "In Stock",
                productName: "Northern Prawn",
                mainAmount: 200.00,
                subAmount: 250.00,
                type: kilo,
                para: "The Northern prawn is a hermaphrodite and starts its life as a male after 4-5 years it turns female. The species is found mostly in areas with soft, muddy sediment where temperatures range from 1-6°C at a depth between 150 and 600 meters."
            },
            {
                discount: "-25%",
                featured: false,
                img: whiteLegShrimp,
                stock: "In Stock",
                productName: "White Leg Shrimp",
                mainAmount: 150.00,
                subAmount: 250.00,
                type: kilo,
                para: "L. vannamei grows to a maximum length of 230 mm (9.1 in),with a carapace length of 90 mm (3.5 in). Adults live in the ocean, at depths to 72 m (236 ft), while juveniles live in estuaries.The rostrum is moderately long, with 7–10 teeth on the dorsal side and two to four teeth on the ventral side."
            },
            {
                discount: "-33%",
                featured: false,
                img: giantTigerShrimp,
                stock: "In Stock",
                productName: "Giant Tiger Shrimp",
                mainAmount: 400.00,
                subAmount: 500.00,
                type: kilo,
                para: "Females can reach about 33 cm (13 in) long, but are typically 25–30 cm (10–12 in) long and weigh 200–320 g (7–11 oz); males are slightly smaller at 20–25 cm (8–10 in) long and weighing 100–170 g (3.5–6.0 oz). The carapace and abdomen are transversely banded with alternative red and white."
            }
        ],
        crab: [
            {
                discount: "-14%",
                featured: true,
                img: dungness,
                stock: "In Stock",
                productName: "Dungness Prawn",
                mainAmount: 100.00,
                subAmount: 150.00,
                type: kilo,
                para: "The Dungeness crab is a decapod, related to shrimp, lobster, and other crab. It has a broad, oval body covered by a hard chitinous shell, four pairs of walking legs and a pair of claws."
            },
            {
                discount: "-14%",
                featured: true,
                img: mud,
                stock: "In Stock",
                productName: "Mud Crab",
                mainAmount: 120.00,
                subAmount: 170.00,
                type: kilo,
                para: "Mud Crab are a member of the family Portunidae. They are large crabs with a smooth, broad carapace and possess sizeable claws used for crushing and cutting prey. Mud Crab are generally very dark brown to mottled green in colour."
            },
            {
                discount: "-25%",
                featured: false,
                img: blueCrab,
                stock: "In Stock",
                productName: "Blue Crab",
                mainAmount: 250.00,
                subAmount: 350.00,
                type: kilo,
                para: "The blue crab's shell—called the “carapace”—is a blue to olive green. Shells can reach up to 9 inches across. Blue crab claws are bright blue, and mature females have red tips on their claws too. They have three pairs of walking legs and rear swimming legs that look like paddles."
            },
            {
                discount: "-33%",
                featured: false,
                img: seaCrab,
                stock: "In Stock",
                productName: "Sea Crab",
                mainAmount: 120.00,
                subAmount: 170.00,
                type: kilo,
                para: "Sea crabs are crustaceans that don't have a backbone and are protected by a hard shell. Their pincers are used catch food and to ﬁght off predators. The thousands of sea crab species can be found in oceans all around the world."
            },
            {
                discount: "-14%",
                featured: true,
                img: stoneCrab,
                stock: "In Stock",
                productName: "Stone Crab",
                mainAmount: 120.00,
                subAmount: 200.00,
                type: kilo,
                para: "Stone Crab can be found in both shallow, nearshore waters as well as deep, offshore benthic environments. Their preferred habitats include sandy/muddy bottoms, rocky outcrops, and seagrass beds."
            }
        ],
        squid: [
            {
                discount: "-50%",
                featured: false,
                img: european,
                stock: "In Stock",
                productName: "European squid",
                mainAmount: 150.00,
                subAmount: 200.00,
                type: kilo,
                para: "The European squid has a long, moderately slender and cylindrical body. Rhomboid ﬁns comprise two-thirds of the mantle length, though locomotion is via jet propulsion. The posterior border is slightly concave."
            },
            {
                discount: "-50%",
                featured: false,
                img: giant,
                stock: "In Stock",
                productName: "Giant squid",
                mainAmount: 200.00,
                subAmount: 300.00,
                type: kilo,
                para: "The giant squid is morphologically similar to smaller squid species, possessing a head, a mantle, and other features associated with cephalopods. It is characterized by the presence of two large ﬁns attached to its mantle, eight arms, and two long tentacles."
            },
            {
                discount: "-50%",
                featured: false,
                img: japaneseFlying,
                stock: "In Stock",
                productName: "Japanese Flying squid",
                mainAmount: 170.00,
                subAmount: 200.00,
                type: kilo,
                para: "The squid has eight arms and two tentacles with suction cups along the underside. In between the arms sits the beak, which housesa toothed, tongue-like appendage called the radula."
            },
            {
                discount: "-34%",
                featured: false,
                img: arrow,
                stock: "In Stock",
                productName: "Arrow squid",
                mainAmount: 110.00,
                subAmount: 150.00,
                type: kilo,
                para: "Both species of Arrow Squid have smooth cylindrical bodies with short pointed ﬁns. Their colour is white with tones of bronze on the mantle and head. The eyes are black. The ﬂesh is ﬁrm and ivory coloured, covered by a speckled membrane."
            },
            {
                discount: "-50%",
                featured: false,
                img: jumbo,
                stock: "In Stock",
                productName: "Jumbo squid",
                mainAmount: 300.00,
                subAmount: 400.00,
                type: kilo,
                para: "The jumbo squid (Dosidicus gigas) is the most abundant cephalopod species in the Southeast Paciﬁc Ocean, ranging from the North American coast to southern Chile. This elusive creature reaches up to 3 meters in length and weighs up to 50 kilograms."
            }
        ],
        pickles_frozenItems: [
            {
                discount: "-34%",
                featured: false,
                img: fishPickle,
                stock: "In Stock",
                productName: `Fish Pickle ${gram100}`,
                mainAmount: 104.00,
                subAmount: 120.00,
                para: "Fish pickle is a delicious and tangy condiment made by marinating pieces of fish in a spicy mixture of vinegar, spices, and oil. This traditional delicacy, popular in many coastal regions, combines the rich flavors of fresh fish with the aromatic intensity of Indian spices."
            },
            {
                discount: "-34%",
                featured: false,
                img: fishKebabMasala,
                stock: "In Stock",
                productName: `Fish Kebab Masala ${gram}`,
                mainAmount: 29.00,
                subAmount: 58.00,
                para: "Fish Kebab Masala is a versatile and aromatic spice blend specifically crafted to elevate the flavors of fish kebabs. This masala mix combines a rich array of spices and herbs, offering a harmonious balance of heat, tang, and savory notes that infuse your fish kebabs with an irresistible taste."
            }, {
                discount: "-34%",
                featured: false,
                img: fishKulambu,
                stock: "In Stock",
                productName: `Fish Kulambu Masala ${gram100}`,
                mainAmount: 30.00,
                subAmount: 40.00,
                para: "Fish Kolambu Masala is a robust and aromatic spice blend specifically crafted to enhance the flavors of traditional South Indian fish curries. This masala mix combines an array of spices and herbs, providing a perfect balance of heat, tang, and aromatic depth that infuses your fish kolambu with an authentic and delectable taste."
            },
            {
                discount: "-34%",
                featured: false,
                img: fish_65,
                stock: "In Stock",
                productName: `Fish 65 Masala ${gram}`,
                mainAmount: 59.00,
                subAmount: 100.00,
                para: "Fish 65 Masala is a specially crafted spice blend designed to create the iconic Fish 65, a popular South Indian appetizer. This masala mix combines a medley of spices and herbs, delivering a perfect balance of heat, tanginess, and savory notes that infuse your fish with a delectable and crispy coating."
            }
        ],
        driedSeaFood: [
            {
                discount: "-34%",
                featured: false,
                img: driedAnchovy,
                stock: "In Stock",
                productName: "Dreid Anchovies",
                mainAmount: 500.00,
                subAmount: 700.00,
                type: kilo,
                para: "These small, silvery ﬁsh are typically sun-dried or sometimes oven-dried until they become hard and brittle. Drying anchovies not only extends their shelf life but also intensiﬁes their unique umami ﬂavor."
            },
            {
                discount: "-50%",
                featured: false,
                img: driedShankara,
                stock: "In Stock",
                productName: "Dried Shankara fish",
                mainAmount: 400.00,
                subAmount: 700.00,
                type: kilo,
                para: "Sankara ﬁsh, also known as Red snapper in English, is a top seafood favourite. Sankara is a popular ﬁsh with a ﬁrm texture, a distinctly sweet ﬂavour, and an imprssive nutritional proﬁle."
            },
            {
                discount: "-14%",
                featured: true,
                img: driedShrimp,
                stock: "In Stock",
                productName: "Dried Baby shrimp",
                mainAmount: 450.00,
                subAmount: 800.00,
                type: kilo,
                para: "Dried shrimp are shrimp that have been sun-dried and shrunk to a thumbnail size. They are used in many East Asian, Southeast Asian and South Asian cuisines, imparting a unique umami taste."
            },
            {
                discount: "-25%",
                featured: false,
                img: driedShell,
                stock: "In Stock",
                productName: "Dried Shell",
                mainAmount: 600.00,
                subAmount: 900.00,
                type: kilo,
                para: "The shell itself is the exoskeleton of a soft-bodied mollusk. Composed mainly of calcium carbonate, the shell is secreted by the animal and provides protection as well as support for various organs"
            },
            {
                discount: "-33%",
                featured: false,
                img: driedCroker,
                stock: "In Stock",
                productName: "Dried croaker fish",
                mainAmount: 400.00,
                subAmount: 600.00,
                type: kilo,
                para: "Dried Croaker Fish contains many essential nutrients, such as omega-3 fatty acids and high quality proteins. Croaker ﬁsh can be processed into many delicious dishes, but usually be dried because it has a strong taste, fast processing and convenient to use."
            },
            {
                discount: "-14%",
                featured: true,
                img: driedMackrelFish,
                stock: "In Stock",
                productName: "Dried Mackrel Fish",
                mainAmount: 300.00,
                subAmount: 500.00,
                type: kilo,
                para: "Dry Mackerel Fish is salt-cured and dried mackerel ﬁsh. It is either sun-dried or dried in a food dehydrator until it is completely dry."
            },
            {
                discount: "-33%",
                featured: false,
                img: driedPrawn,
                stock: "In Stock",
                productName: "Dried Prawn",
                mainAmount: 500.00,
                subAmount: 600.00,
                type: kilo,
                para: "Tasty Nibbles Dried Prawns are wild caught Prawns that have been hygienically dried and shrunk to a thumbnail size. They are used in many Indian, East Asian, Southeast Asian, and South Asian cuisines, unique umami taste."
            },
            {
                discount: "-14%",
                featured: true,
                img: driedEel,
                stock: "In Stock",
                productName: "Dried eel fish",
                mainAmount: 300.00,
                subAmount: 500.00,
                type: kilo,
                para: "Eels are catadromous, meaning they primarily live in rivers and estuaries, but migrate out to the ocean - the Sargasso Sea, to spawn. American eels are an elongated ﬁsh, with fairly small ﬁns."
            },
            {
                discount: "-14%",
                featured: true,
                img: driedBombayDuckFish,
                stock: "In Stock",
                productName: "Dried bombay duck fish",
                mainAmount: 300.00,
                subAmount: 500.00,
                type: kilo,
                para: "Bombay duck ﬁsh of the family Synodontidae, found in estuaries of northern India, where it is widely used as a food ﬁsh and, when dried,or brown in colour with small, dark speckles."
            }
        ],
    }

    const mapProductValue = productList[propsProductValue];

    // Convert to modal content

    const [moveContent, setMoveContent] = useState([]);

    return (
        <div>
            <div className="product_div_overall" style={{ paddingBottom: "30px" }}>
                {mapProductValue.map((product) => (
                    <div className="product_div">
                        <div className="product_discount_div">
                            <div>
                                <span className="product_dicount">{product.discount}</span>
                                <span className={product.featured === true ? "product_featured" : "product_featured_none"}>Featured</span>
                            </div>
                            <div className="product_cart_div">
                                <div>
                                    <button className="product_heart" custom_tooltip="Upcoming">
                                        <i class="fa-regular fa-heart"></i>
                                    </button>
                                </div>
                                <div>
                                    <button className="product_eye" custom_tooltip="Quick View" onClick={() => { setMoveContent(product) }} type="button" data-bs-toggle="modal" data-bs-target="#exampleModalAllProduct">
                                        <i class="fa-regular fa-eye"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="modal fade" id="exampleModalAllProduct" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered modal-xl" >
                                    <div class="modal-content" id="product_modal_width">
                                        <div class="modal-body" id="product_modal_content_overall">
                                            <div className="productTwo_closeMob">
                                                <button id="productTwo_close" type="button" data-bs-dismiss="modal" aria-label="Close">
                                                    <i class="fa-solid fa-xmark fa-lg"></i>
                                                </button>
                                            </div>
                                            <div className="product_modal_one">
                                                <img src={moveContent.img} alt="product_img"></img>
                                            </div>
                                            <div className="product_modal_two">
                                                <div className="productTwo_title">
                                                    <div>
                                                        <h4 className="productTwo_title_size">{moveContent.productName}</h4>
                                                    </div>
                                                    <div className="productTwo_closeDesktop">
                                                        <button id="productTwo_close" type="button" data-bs-dismiss="modal" aria-label="Close">
                                                            <i class="fa-solid fa-xmark fa-lg"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="productTwo_rateDiv">
                                                        <div className="product_rate">
                                                            <span className="productTwo_main_rate">₹ {moveContent.mainAmount} {moveContent.type}</span>
                                                            <span className="productTwo_sub_rate">₹{moveContent.subAmount}</span>
                                                        </div>
                                                        <div className="productTwo_stock_gap">
                                                            <span className={moveContent.stock === "In Stock" ? "productStock_update" : "productOutOf_stock_update"} >
                                                                <span id="productTwo_dot">•</span> {moveContent.stock === "In Stock" ? "In Stock" : "Out Of Stock"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="productTwo_contentDiv">
                                                    <p className="productTwo_para">
                                                        {moveContent.para}
                                                    </p>
                                                </div>
                                                <div className="productTwo_BuyNow">
                                                    <button className="productTwo_enquiryBtn" onClick={handleChatSubmit}>
                                                        <i class="fa-solid fa-phone" style={{ color: "#ffffff", fontSize: "13.5px" }}></i>
                                                        <span style={{ marginLeft: "6px" }}>Buy Now</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="product_img_div">
                                <img className="product_img" src={product.img} alt="demo" />
                            </div>
                        </div>
                        <div>
                            <div >
                                <ul className={product.stock === "In Stock" ? "stock_update" : "outOf_stock_update"} >
                                    <li>{product.stock === "In Stock" ? "In Stock" : "Out Of Stock"}</li>
                                </ul>
                            </div>
                            <div>
                                <Link className="product_name_link">{product.productName}</Link>
                            </div>
                            <div className="product_rate">
                                <span className="product_main_rate">₹ {product.mainAmount} {product.type}</span>
                                <span className="product_sub_rate">₹{product.subAmount}</span>
                            </div>
                        </div>

                    </div>
                ))
                }
            </div>
        </div>
    )
}

export const HotDeals = ({ time }) => {

    const kilo = " / kg";

    const gram = "- (50 g)";

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        rtl: true,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 4,
                }
            }
            ,
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 924,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 642,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    const productList = [
        {
            discount: "-34%",
            featured: false,
            img: salmon,
            stock: "In Stock",
            productName: "Salmon fish",
            mainAmount: 400.00,
            subAmount: 600.00,
            type: kilo,
            para: "They're found in tributaries of the Paciﬁc and Atlantic Oceans, and most species are anadromous: They are born in streams and rivers, migrate out to the open sea, and then return to freshwater again to reproduce. Salmons have color throughout their lifetimes."
        },
        {
            discount: "-50%",
            featured: false,
            img: rohu,
            stock: "In Stock",
            productName: "Rohu fish",
            mainAmount: 100.00,
            subAmount: 150.00,
            type: kilo,
            para: "The rohu is a large, silver-colored ﬁsh of typical cyprinid shape, with a conspicuously arched head. Adults can reach a maximum weight of 45 kg (99 lb) and maximum length of 2 m (6.6 ft), but average around 1⁄2 m (1.6 ft)."
        },
        {
            discount: "-14%",
            featured: true,
            img: blackTigerShrimp,
            stock: "In Stock",
            productName: "Black Tiger Shrimp",
            mainAmount: 350.00,
            subAmount: 450.00,
            type: kilo,
            para: "The Black Tiger Shrimp's natural range is vast, from the east coast of Africa, throughout the Indian Ocean, and in South Asia. Black Tiger Shrimp are named for their large size and striped tails and can reach a length of over a foot in some cases."
        },
        {
            discount: "-25%",
            featured: false,
            img: seaCrab,
            stock: "In Stock",
            productName: "Sea Crab",
            mainAmount: 120.00,
            subAmount: 170.00,
            type: kilo,
            para: "Sea crabs are crustaceans that don't have a backbone and are protected by a hard shell. Their pincers are used catch food and to ﬁght off predators. The thousands of sea crab species can be found in oceans all around the world."
        },
        {
            discount: "-33%",
            featured: false,
            img: jumbo,
            stock: "In Stock",
            productName: "Jumbo Squid",
            mainAmount: 300.00,
            subAmount: 400.00,
            type: kilo,
            para: "The jumbo squid (Dosidicus gigas) is the most abundant cephalopod species in the Southeast Paciﬁc Ocean, ranging from the North American coast to southern Chile. This elusive creature reaches up to 3 meters in length and weighs up to 50 kilograms."
        },
        {
            discount: "-14%",
            featured: true,
            img: driedBombayDuckFish,
            stock: "In Stock",
            productName: "Dried bombay duck fish",
            mainAmount: 300.00,
            subAmount: 500.00,
            type: kilo,
            para: "Bombay duck ﬁsh of the family Synodontidae, found in estuaries of northern India, where it is widely used as a food ﬁsh and, when dried,or brown in colour with small, dark speckles."
        },
        {
            discount: "-25%",
            featured: false,
            img: fishKebabMasala,
            stock: "In Stock",
            productName: `Fish Kebab Masala ${gram}`,
            mainAmount: 29.00,
            subAmount: 58.00,
            para: "Fish Kebab Masala is a versatile and aromatic spice blend specifically crafted to elevate the flavors of fish kebabs. This masala mix combines a rich array of spices and herbs, offering a harmonious balance of heat, tang, and savory notes that infuse your fish kebabs with an irresistible taste."
        }
    ]

    // Convert to modal content

    const whatsappNumber = "6366192865";

    const whatsappUrl = `https://wa.me/${whatsappNumber}`;

    const handleChatSubmit = (e) => {
        e.preventDefault();
        window.open(whatsappUrl, "_blank")
    }

    const [moveContent, setMoveContent] = useState([]);

    return (
        <div>
            <div className="hotDeal_title">
                <div className="hotDeal_inner_title">
                    <h5 className="hotDeal_title_content">Hot Deals!</h5>
                    <h6 className="hotDeal_subtitle_content">Remains until the end of the offer</h6>
                </div>
                <div>
                    <div className="hotDeal_timer_overall">
                        <div>
                            <span className="hotDeal_time">{time.startDay}</span>
                        </div>
                        <div>
                            <span className="hotDeal_time_dot">:</span>
                        </div>
                        <div>
                            <span className="hotDeal_time">{time.startHours}</span>
                        </div>
                        <div>
                            <span>:</span>
                        </div>
                        <div>
                            <span className="hotDeal_time">{time.startMinutes}</span>
                        </div>
                        <div>
                            <span>:</span>
                        </div>
                        <div>
                            <span className="hotDeal_time">{time.startSeconds}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slider-container">
                <Slider {...settings}>
                    {productList.map((product) => (
                        <div id="hotDeal_div_overall">
                            <div className="product_div">
                                <div className="product_discount_div">

                                    <div>
                                        <span className="product_dicount">{product.discount}</span>
                                        <span className={product.featured === true ? "product_featured" : "product_featured_none"}>Featured</span>
                                    </div>

                                    <div className="product_cart_div">
                                        <div>
                                            <button className="product_heart" custom_tooltip="Upcoming">
                                                <i class="fa-regular fa-heart"></i>
                                            </button>
                                        </div>
                                        <div>
                                            <button className="product_eye" custom_tooltip="Quick View" onClick={() => { setMoveContent(product) }} type="button" data-bs-toggle="modal" data-bs-target="#modalHotDeals">
                                                <i class="fa-regular fa-eye"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>

                                <div>
                                    <div className="product_img_div">
                                        <img className="product_img" src={product.img} alt="demo" />
                                    </div>
                                </div>

                                <div>
                                    <div >
                                        <ul className={product.stock === "In Stock" ? "stock_update" : "outOf_stock_update"} >
                                            <li>{product.stock === "In Stock" ? "In Stock" : "Out Of Stock"}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <Link className="product_name_link">{product.productName}</Link>
                                    </div>
                                    <div className="product_rate">
                                        <span className="product_main_rate">₹ {product.mainAmount} {product.type}</span>
                                        <span className="product_sub_rate">₹{product.subAmount}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    ))
                    }
                </Slider>

                <div class="modal fade" id="modalHotDeals" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-xl" >
                        <div class="modal-content" id="product_modal_width">
                            <div class="modal-body" id="product_modal_content_overall">
                                <div className="productTwo_closeMob">
                                    <button id="productTwo_close" type="button" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="fa-solid fa-xmark fa-lg"></i>
                                    </button>
                                </div>
                                <div className="product_modal_one">
                                    <img src={moveContent.img} alt="product_img"></img>
                                </div>
                                <div className="product_modal_two">
                                    <div className="productTwo_title">
                                        <div>
                                            <h4 className="productTwo_title_size">{moveContent.productName}</h4>
                                        </div>
                                        <div className="productTwo_closeDesktop">
                                            <button id="productTwo_close" type="button" data-bs-dismiss="modal" aria-label="Close">
                                                <i class="fa-solid fa-xmark fa-lg"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="productTwo_rateDiv">
                                            <div className="product_rate">
                                                <span className="productTwo_main_rate">₹ {moveContent.mainAmount} {moveContent.type}</span>
                                                <span className="productTwo_sub_rate">₹{moveContent.subAmount}</span>
                                            </div>
                                            <div className="productTwo_stock_gap">
                                                <span className={moveContent.stock === "In Stock" ? "productStock_update" : "productOutOf_stock_update"} >
                                                    <span id="productTwo_dot">•</span> {moveContent.stock === "In Stock" ? "In Stock" : "Out Of Stock"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="productTwo_contentDiv">
                                        <p className="productTwo_para">
                                            {moveContent.para}
                                        </p>
                                    </div>
                                    <div className="productTwo_BuyNow">
                                        <button className="productTwo_enquiryBtn" onClick={handleChatSubmit}>
                                            <i class="fa-solid fa-phone" style={{ color: "#ffffff", fontSize: "13.5px" }}></i>
                                            <span style={{ marginLeft: "6px" }}>Buy Now</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const PropularProduct = () => {

    const kilo = " / kg";

    const gram = "- (50 g)";

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        // autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 924,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 642,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    const productList = [
        {
            discount: "-34%",
            featured: false,
            img: tuna,
            stock: "In Stock",
            productName: "Tuna Fish",
            mainAmount: 100.00,
            subAmount: 200.00,
            type: kilo,
            para: "Tuna are remarkable and impressive wild animals. The Atlantic blueﬁn can reach ten feet in length and weigh as much as 2,000 pounds (more than a horse).Their specialized body shape, ﬁns, and scales enable some species of tuna to swim as fast as 43 miles per hour."
        },
        {
            discount: "-50%",
            featured: false,
            img: northernPrawn,
            stock: "In Stock",
            productName: "Northern Prawn",
            mainAmount: 200.00,
            subAmount: 250.00,
            type: kilo,
            para: "The Northern prawn is a hermaphrodite and starts its life as a male after 4-5 years it turns female. The species is found mostly in areas with soft, muddy sediment where temperatures range from 1-6°C at a depth between 150 and 600 meters."
        },
        {
            discount: "-14%",
            featured: true,
            img: tilapia,
            stock: "In Stock",
            productName: "Tilapia fish",
            mainAmount: 100.00,
            subAmount: 150.00,
            type: kilo,
            para: "Tilapia are a hardy, fast growing ﬁsh, that can live up to ten years and reach ten pounds in weight. Tilapia are shaped like a sunﬁsh or crappie and are easily identiﬁable by the interrupted lateral line characteristic of the Chiclid family of ﬁshes."
        },
        {
            discount: "-25%",
            featured: false,
            img: european,
            stock: "In Stock",
            productName: "European squid",
            mainAmount: 150.00,
            subAmount: 200.00,
            type: kilo,
            para: "The European squid has a long, moderately slender and cylindrical body. Rhomboid ﬁns comprise two-thirds of the mantle length, though locomotion is via jet propulsion. The posterior border is slightly concave."
        },
        {
            discount: "-33%",
            featured: false,
            img: mud,
            stock: "In Stock",
            productName: "Mud Crab",
            mainAmount: 120.00,
            subAmount: 170.00,
            type: kilo,
            para: "Mud Crab are a member of the family Portunidae. They are large crabs with a smooth, broad carapace and possess sizeable claws used for crushing and cutting prey. Mud Crab are generally very dark brown to mottled green in colour."
        },
        {
            discount: "-14%",
            featured: true,
            img: milk,
            stock: "In Stock",
            productName: "Milk fish",
            mainAmount: 200.00,
            subAmount: 300.00,
            type: kilo,
            para: "Milkﬁsh have a spindle-like shape that is moderately compressed, smooth and streamlined. Its body color is silvery on the belly and sides, grading to olive green or blue on the back. The wild catch is limited to just slightly more than one percent of total world production."
        },
        {
            discount: "-25%",
            featured: false,
            img: fish_65,
            stock: "In Stock",
            productName: `Fish 65 Masala ${gram}`,
            mainAmount: 59.00,
            subAmount: 100.00,
            para: "Fish 65 Masala is a specially crafted spice blend designed to create the iconic Fish 65, a popular South Indian appetizer. This masala mix combines a medley of spices and herbs, delivering a perfect balance of heat, tanginess, and savory notes that infuse your fish with a delectable and crispy coating."
        }
    ]

    // Convert to modal content

    const whatsappNumber = "6366192865";

    const whatsappUrl = `https://wa.me/${whatsappNumber}`;

    const handleChatSubmit = (e) => {
        e.preventDefault();
        window.open(whatsappUrl, "_blank")
    }

    const [moveContent, setMoveContent] = useState([]);

    return (
        <div>
            <div className="hotDeal_title">
                <div className="hotDeal_inner_title">
                    <h5 className="hotDeal_title_content">Recommend Products</h5>
                    {/* <span className="hotDeal_subtitle_content"></span> */}
                </div>
                <div>
                    <Link to={"allProducts"} smooth={true} duration={500} className="view_all_btn">
                        <div>
                            <span>View All</span>
                        </div>
                        <div>
                            <i id="viewAll_arrow_icon" class="fa-solid fa-arrow-right-long"></i>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="slider-container">
                <Slider {...settings}>
                    {productList.map((product) => (
                        <div id="hotDeal_div_overall">
                            <div className="product_div">
                                <div className="product_discount_div">
                                    <div>
                                        <span className="product_dicount">{product.discount}</span>
                                        <span className={product.featured === true ? "product_featured" : "product_featured_none"}>Featured</span>
                                    </div>

                                    <div className="product_cart_div">
                                        <div>
                                            <button className="product_heart" custom_tooltip="Upcoming">
                                                <i class="fa-regular fa-heart"></i>
                                            </button>
                                        </div>
                                        <div>
                                            <button className="product_eye" custom_tooltip="Quick View" onClick={() => { setMoveContent(product) }} type="button" data-bs-toggle="modal" data-bs-target="#modalRecommendProduct">
                                                <i class="fa-regular fa-eye"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="product_img_div">
                                        <img className="product_img" src={product.img} alt="demo" />
                                    </div>
                                </div>

                                <div>
                                    <div >
                                        <ul className={product.stock === "In Stock" ? "stock_update" : "outOf_stock_update"} >
                                            <li>{product.stock === "In Stock" ? "In Stock" : "Out Of Stock"}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <Link className="product_name_link">{product.productName}</Link>
                                    </div>
                                    <div className="product_rate">
                                        <span className="product_main_rate">₹ {product.mainAmount} {product.type
                                        }</span>
                                        <span className="product_sub_rate">₹{product.subAmount} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </Slider>

                <div class="modal fade" id="modalRecommendProduct" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-xl" >
                        <div class="modal-content" id="product_modal_width">
                            <div class="modal-body" id="product_modal_content_overall">
                                <div className="productTwo_closeMob">
                                    <button id="productTwo_close" type="button" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="fa-solid fa-xmark fa-lg"></i>
                                    </button>
                                </div>
                                <div className="product_modal_one">
                                    <img src={moveContent.img} alt="product_img"></img>
                                </div>
                                <div className="product_modal_two">
                                    <div className="productTwo_title">
                                        <div>
                                            <h4 className="productTwo_title_size">{moveContent.productName}</h4>
                                        </div>
                                        <div className="productTwo_closeDesktop">
                                            <button id="productTwo_close" type="button" data-bs-dismiss="modal" aria-label="Close">
                                                <i class="fa-solid fa-xmark fa-lg"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="productTwo_rateDiv">
                                            <div className="product_rate">
                                                <span className="productTwo_main_rate">₹ {moveContent.mainAmount} {moveContent.type}</span>
                                                <span className="productTwo_sub_rate">₹{moveContent.subAmount}</span>
                                            </div>
                                            <div className="productTwo_stock_gap">
                                                <span className={moveContent.stock === "In Stock" ? "productStock_update" : "productOutOf_stock_update"} >
                                                    <span id="productTwo_dot">•</span> {moveContent.stock === "In Stock" ? "In Stock" : "Out Of Stock"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="productTwo_contentDiv">
                                        <p className="productTwo_para">
                                            {moveContent.para}
                                        </p>
                                    </div>
                                    <div className="productTwo_BuyNow">
                                        <button className="productTwo_enquiryBtn" onClick={handleChatSubmit}>
                                            <i class="fa-solid fa-phone" style={{ color: "#ffffff", fontSize: "13.5px" }}></i>
                                            <span style={{ marginLeft: "6px" }}>Buy Now</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}