import "../styles/App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "../../pages/user/home";
import { Contact } from "../../pages/user/contact";
import { Faq } from "../../pages/user/Faq";
function App() {

  return (
    <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route path="/contact" element={<Contact/>}></Route>
            <Route path="/contact" element={<Contact/>}></Route>
            <Route path="/faq" element={<Faq/>}></Route>

          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
