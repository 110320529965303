import { HotDeals } from "../../components/user/products";
import { Products } from "../../components/user/products";
import { PropularProduct } from "../../components/user/products";
import banner1 from "../../assests/user/images/trendy_banner_111.png";
import banner2 from "../../assests/user/images/trendy_banner_22.png";
import "../../styles/user/home.css";
import doubleBanner from "../../assests/user/images/double_banner_bg1.jpg";
import doubleBannerMobile from "../../assests/user/images/double_banner_mobileImg1.png";
// benefits images
import van from "../../assests/user/images/delivery_van.png";
import payment from "../../assests/user/images/secure_payment.png";
import phone from "../../assests/user/images/telephone.png";
import moneyBack from "../../assests/user/images/money_back.png";
import discount from "../../assests/user/images/discount.png";
// Image Banner content images
import two_men from "../../assests/user/images/two_men.jpeg";
import bg_fish from "../../assests/user/images/bg_fish.jpg";
import man_fishing from "../../assests/user/images/man_fishing.png";
// Carousel Images
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import carousel_1 from "../../assests/user/images/img_3.jpg";
import carousel_2 from "../../assests/user/images/img_2.jpg";
import carousel_3 from "../../assests/user/images/img_1_blur.gif";
import carousel_side from "../../assests/user/images/carousel_side.jpg";

// Comments Images

// import cmt_1 from "../../assests/user/images/cmt_1.jpg";
// import cmt_2 from "../../assests/user/images/cmt_2.jpg";
// import cmt_3 from "../../assests/user/images/cmt_3.jpg";

// Header Images
import logo from "../../main_folder/assests/photos/seaboss_logo.png";

import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Link } from "react-scroll";
import { ScrolltoTopSection } from "../../components/user/Scroll_to_Top_Section";

function SampleNextArrow(props) {
  const [nextArrowStyle, setNextArrowStyle] = useState({
    display: "block",
    marginRight: "60px",
  });

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth <= 580) {
        setNextArrowStyle({
          display: "none",
          marginRight: "60px",
        });
      } else {
        setNextArrowStyle({
          display: "block",
          marginRight: "60px",
        });
      }
    };

    resize();

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, ...nextArrowStyle }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  const [nextPrevStyle, setNextPrevStyle] = useState({
    display: "block",
    marginLeft: "50px",
    zIndex: "1",
  });

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth <= 580) {
        setNextPrevStyle({
          display: "none",
          marginRight: "60px",
        });
      } else {
        setNextPrevStyle({
          display: "block",
          marginLeft: "50px",
          zIndex: "1",
        });
      }
    };

    resize();

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);
  return (
    <div
      className={className}
      style={{ ...style, ...nextPrevStyle }}
      onClick={onClick}
    />
  );
}

// function SampleCommentNextArrow(props) {
//     const { className, style, onClick } = props;

//     return (
//         <div
//             className={className}
//             style={{ ...style, display: "block", marginRight: "60px", zIndex: "2" }}
//             onClick={onClick}
//         />
//     );
// }

// function SampleCommentPrevArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={className}
//             style={{ ...style, display: "block", marginLeft: "50px", color: "#000", zIndex: "2" }}
//             onClick={onClick}
//         />
//     );
// }

export const Home = () => {
  // Carousel Section

  const settingsMainCarousel = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const [btnValue, setBtnValue] = useState("seaFish");

  const handleTopSellingBtn = (dataName) => {
    setBtnValue(dataName);
  };

  // Timer Logic

  const [launchDate, setLaunchDate] = useState("2024-07-11T00:00:00");

  const [startDay, setStartDay] = useState("00");
  const [startHours, setStartHours] = useState("00");
  const [startMinutes, setStartMinutes] = useState("00");
  const [startSeconds, setStartSeconds] = useState("00");

  const times = { startDay, startHours, startMinutes, startSeconds };

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = +new Date(launchDate) - +new Date();
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      } else {
        timeLeft = {
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
        };
      }

      return timeLeft;
    };

    const updateTimer = () => {
      let timeLeft = calculateTimeLeft();

      if (
        timeLeft.days === "00" &&
        timeLeft.hours === "00" &&
        timeLeft.minutes === "00" &&
        timeLeft.seconds === "00"
      ) {
        const newLaunchDate = new Date(launchDate);
        newLaunchDate.setDate(newLaunchDate.getDate() + 5);
        setLaunchDate(newLaunchDate.toISOString());
        timeLeft = calculateTimeLeft();
      }

      setStartDay(timeLeft.days.toString().padStart(2, "0"));
      setStartHours(timeLeft.hours.toString().padStart(2, "0"));
      setStartMinutes(timeLeft.minutes.toString().padStart(2, "0"));
      setStartSeconds(timeLeft.seconds.toString().padStart(2, "0"));
    };
    const timer = setInterval(updateTimer, 1000);
    updateTimer();

    return () => clearInterval(timer);
  }, [launchDate]);

  // Comment Section

  // const [dotVisible, setDotVisible] = useState({
  //     dots: false,
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     nextArrow: <SampleCommentNextArrow />,
  //     prevArrow: <SampleCommentPrevArrow />
  // })

  // useEffect(() => {
  //     const dotShow = () => {
  //         if (window.innerWidth <= 500) {
  //             setDotVisible({
  //                 dots: true,
  //                 infinite: true,
  //                 speed: 500,
  //                 slidesToShow: 1,
  //                 slidesToScroll: 1,
  //                 arrows: false
  //             })
  //         }
  //         else {
  //             setDotVisible({
  //                 dots: false,
  //                 infinite: true,
  //                 speed: 500,
  //                 slidesToShow: 1,
  //                 slidesToScroll: 1,
  //                 nextArrow: <SampleCommentNextArrow />,
  //                 prevArrow: <SampleCommentPrevArrow />
  //             })
  //         }
  //     }
  //     dotShow()

  //     window.addEventListener("resize", dotShow);

  //     return () => {
  //         window.removeEventListener("resize", dotShow);
  //     }
  // }, [])

  // Chat Content

  const [chatContent, setChatContent] = useState("");

  const whatsappNumber = "6366192865";

  const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
    chatContent
  )}`;

  const handleChatSubmit = (e) => {
    e.preventDefault();
    window.open(whatsappUrl, "_blank");
  };

  // Scroll Section

  // const [scrollOpen, setScrollOpen] = useState(false);

  // useEffect(() => {
  //     const reHeight = () => {
  //         if (window.scrollY >= 100) {
  //             setScrollOpen(true);
  //         }
  //         else {
  //             setScrollOpen(false);
  //         }
  //     }

  //     reHeight();

  //     window.addEventListener("scroll", reHeight);

  //     return () => {
  //         window.removeEventListener("scroll", reHeight);
  //     }
  // }, [])

  // const handleScroll = () => {
  //     window.scrollTo({
  //         top: 0,
  //         behavior: "smooth"
  //     })
  // }

  // Footer Section

  const handleInsta = () => {
    window.open("https://www.instagram.com/seabossfishmarts/", "_blank");
  };

  const handleFb = () => {
    window.open(
      "https://www.facebook.com/people/Seaboss-Fishmart/pfbid02QKQk2Sga5oFDF2BrUviSP9gguEJbq33Ub4za4oWneu9HzwEYByjYLgck5dEppVgdl/?mibextid=ZbWKwL",
      "_blank"
    );
  };

  const handleYoutube = () => {
    window.open("https://www.youtube.com/@Seabossfishmart", "_blank");
  };

    // Franchises Section

    const handleFranchises = () => {
        window.open('https://seabossfishfries.in/', '_blank'); 
      };

    return (
        <div>

            <div className="main_body_div">
                <div className="body_content">
                    <div>

                        {/* Header Section */}
                        <section id="header">
                            <header className="header_main">
                                <div className="header_overall">
                                    <div>
                                        <Link to="/"><img className="header_log" alt="fishing" src={logo}></img></Link>
                                    </div>
                                    <div className="header_links_content">
                                        <div>
                                            <Link className="header_link_name" to={"/"} smooth={true} duration={500}>Home</Link>
                                        </div>
                                        <div>
                                            <Link className="header_link_name" to={"benefits"} smooth={true} duration={500}>Benefits</Link>
                                        </div>
                                        <div>
                                            <Link className="header_link_name" to={"hotDeals"} smooth={true} duration={500}>Hot Deals</Link>
                                        </div>
                                        <div>
                                            <Link className="header_link_name" to={"recommends"} smooth={true} duration={500}>Recommends</Link>
                                        </div>
                                        <div>
                                            <Link className="header_link_name" to={"allProducts"} smooth={true} duration={500}>All Products</Link>
                                        </div>
                                    </div>
                                    <div className="header_btn_div">
                                        <Link to={"contactUs"} smooth={true} duration={500}><button className="header_btn">CONTACT US</button></Link>
                                        <div className="menu_bar_mobile">
                                            <span id="menu_bar_span" class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExampleSideBar" aria-controls="offcanvasExample">
                                                <i id="menu_bar_mobile_icon" class="fa-solid fa-bars fa-xl"></i>
                                            </span>
                                        </div>
                                        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExampleSideBar" aria-labelledby="offcanvasExampleLabel">
                                            <div class="offcanvas-header" id="sidebar_border">
                                                <img className="header_log" alt="fishing" src={logo}></img>
                                                <button id="offcanvas_sidebar_close_btn" data-bs-dismiss="offcanvas" aria-label="Close"><i id="sidebar_close_btn" class="fa-solid fa-xmark"></i></button>
                                            </div>
                                            <div class="offcanvas-body" id="sidebar_body">
                                                <div>
                                                    <div className="header_link_name_sidebar_div" style={{ paddingTop: "0px" }}>
                                                        <Link to={"/"} smooth={true} duration={500} data-bs-dismiss="offcanvas" aria-label="Close">
                                                            <button className="header_link_name_sidebar">Home</button>
                                                        </Link>
                                                    </div>
                                                    <div className="header_link_name_sidebar_div">
                                                        <Link to={"benefits"} smooth={true} duration={500} data-bs-dismiss="offcanvas" aria-label="Close">
                                                            <button className="header_link_name_sidebar">Benefits</button>
                                                        </Link>
                                                    </div>
                                                    <div className="header_link_name_sidebar_div">
                                                        <Link to={"hotDeals"} smooth={true} duration={500} data-bs-dismiss="offcanvas" aria-label="Close">
                                                            <button className="header_link_name_sidebar">Hot Deals</button>
                                                        </Link>
                                                    </div>
                                                    <div className="header_link_name_sidebar_div">
                                                        <Link to={"recommends"} smooth={true} duration={500} data-bs-dismiss="offcanvas" aria-label="Close">
                                                            <button className="header_link_name_sidebar">Recommends</button>
                                                        </Link>
                                                    </div>
                                                    <div className="header_link_name_sidebar_div">
                                                        <Link to={"allProducts"} smooth={true} duration={500} data-bs-dismiss="offcanvas" aria-label="Close">
                                                            <button className="header_link_name_sidebar">All Products</button>
                                                        </Link>
                                                    </div>
                                                    <div className="header_link_name_sidebar_div">
                                                        <Link to={"contactUs"} smooth={true} duration={500} data-bs-dismiss="offcanvas" aria-label="Close">
                                                            <button className="header_link_name_sidebar">Contact Us</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="copyright_sidebar_mobile">
                                                <div className="copyright_sidebar_first">© 2024 Seaboss Fish Mart, All Rights Reserved.</div>
                                                <div className="copyright_sidebar_second">Designed By Seaboss</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                        </section>

            {/* Carousel Section */}
            <section style={{ position: "relative" }}>
              <div className="carousel_flexDiv_overall">
                <div className="carousel_flexDiv">
                  <div className="carousel_flexDiv_one">
                    <div className="slider-container" id="carousel_mainPage">
                      <Slider {...settingsMainCarousel}>
                        <div className="image_carousel">
                          <img
                            id="carousel_flexDiv_img"
                            src={carousel_3}
                            alt="fishing"
                          ></img>
                          <div className="image_carousel_content">
                            <div>
                              <div>
                                <h2 className="image_carousel_main_title">
                                  Dive into Success with Seaboss Fish Franchise
                                </h2>
                              </div>
                              <div>
                                <p className="image_carousel_main_subtitle">
                                  " Transform Your Passion for Seafood into a
                                  Thriving Business "
                                </p>
                              </div>
                              <div>
                                <Link
                                  to={"contactUs"}
                                  smooth={true}
                                  duration={500}
                                >
                                  <button className="image_carousel_btn">
                                    SHOP NOW
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="image_carousel">
                          <img
                            id="carousel_flexDiv_img"
                            src={carousel_1}
                            alt="fishing"
                          ></img>
                          <div className="image_carousel_content">
                            <div>
                              <div>
                                <h2 className="image_carousel_main_title">
                                  Sail into Success with Seaboss Fish Franchise
                                </h2>
                              </div>
                              <div>
                                <p className="image_carousel_main_subtitle">
                                  " Chart Your Course to Business Prosperity
                                  with Our Franchise Model "
                                </p>
                              </div>
                              <div>
                                <Link
                                  to={"contactUs"}
                                  smooth={true}
                                  duration={500}
                                >
                                  <button className="image_carousel_btn">
                                    SHOP NOW
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="image_carousel">
                          <img
                            id="carousel_flexDiv_img"
                            src={carousel_2}
                            alt="fishing"
                          ></img>
                          <div className="image_carousel_content">
                            <div>
                              <div>
                                <h2 className="image_carousel_main_title">
                                  Discover the Benefits of Seaboss Fish
                                  Franchise
                                </h2>
                              </div>
                              <div>
                                <p className="image_carousel_main_subtitle">
                                  " Your Ultimate Partner in Building a
                                  Successful Fish Franchise Business "
                                </p>
                              </div>
                              <div>
                                <Link
                                  to={"contactUs"}
                                  smooth={true}
                                  duration={500}
                                >
                                  <button className="image_carousel_btn">
                                    SHOP NOW
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                  <div className="carousel_flexDiv_two">
                    <img
                      className="carousel_flexDiv_two_img"
                      src={carousel_side}
                      alt="fishing"
                    ></img>
                    <div className="carousel_flexDiv_two_absolute">
                      <div>
                        <div>
                          <h6 className="carousel_side_title">50% Off</h6>
                        </div>
                        <div>
                          <h3 className="carousel_side_subtitle">
                            Premium Fishes
                          </h3>
                        </div>
                        <div>
                          <Link
                            to={"contactUs"}
                            smooth={true}
                            duration={500}
                            className="carousel_view_all_btn"
                          >
                            <div>
                              <span>SHOP NOW</span>
                            </div>
                            <div>
                              <i
                                id="carousel_viewAll_arrow_icon"
                                class="fa-solid fa-arrow-right-long"
                              ></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel_flexDiv_two_mobile">
                    <img
                      className="carousel_flexDiv_two_img"
                      src={carousel_side}
                      alt="fishing"
                    ></img>
                    <div className="carousel_flexDiv_two_absolute">
                      <div>
                        <div>
                          <h6 className="carousel_side_title">25% Off</h6>
                        </div>
                        <div>
                          <h3 className="carousel_side_subtitle">
                            Premium Reels
                          </h3>
                        </div>
                        <div>
                          <Link
                            to={"contactUs"}
                            smooth={true}
                            duration={500}
                            className="carousel_view_all_btn"
                          >
                            <div>
                              <span>SHOP NOW</span>
                            </div>
                            <div>
                              <i
                                id="carousel_viewAll_arrow_icon"
                                class="fa-solid fa-arrow-right-long"
                              ></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>        
              </div>
            </section>

            {/* Scroll to Top Section */}
            <ScrolltoTopSection />
            {/* {scrollOpen === true ?
                            (<section className="scroll_btn_overall">
                                <div>
                                    <button className="scroll_btn" onClick={handleScroll}><i id="scroll_btn_icon" class="fa-solid fa-chevron-up fa-lg"></i></button>
                                </div>
                            </section>)
                            : ""
                        } */}

                        {/* Benefits Content */}
                        <section id="benefits">
                            <div className="benefits_main_title_div" >
                                <h5 className="benefits_main_title">Benefits</h5>
                                <h6 className="benefits_main_subtitle">Services we are offered!</h6>
                            </div>
                            <div className="benefits_overall">
                                <div className="benefits_inner" id="benefits_inner">
                                    <div className="benefits_img_div">
                                        <img className="benefits_img" src={van} alt="delivery_van"></img>
                                    </div>
                                    <div className="benefits_content_div" id="benefits_content_div">
                                        <h5 className="benefits_title_name">Free Transport</h5>
                                        <p className="benefits_subtitle_name">Deliver in 24 hours max!</p>
                                    </div>
                                </div>
                                <div className="benefits_inner">
                                    <div className="benefits_img_div">
                                        <img className="benefits_img_big" src={payment} alt="delivery_van"></img>
                                    </div>
                                    <div className="benefits_content_div">
                                        <h6 className="benefits_title_name">Secure Payment</h6>
                                        <p className="benefits_subtitle_name">100% secure payment</p>
                                    </div>
                                </div>
                                <div className="benefits_inner">
                                    <div className="benefits_img_div">
                                        <img className="benefits_img_big" src={phone} alt="delivery_van"></img>
                                    </div>
                                    <div className="benefits_content_div">
                                        <h5 className="benefits_title_name">Customer Services</h5>
                                        <p className="benefits_subtitle_name">Dedicated 24/7 support</p>
                                    </div>
                                </div>
                                <div className="benefits_inner">
                                    <div className="benefits_img_div">
                                        <img className="benefits_img_big" src={moneyBack} alt="delivery_van"></img>
                                    </div>
                                    <div className="benefits_content_div">
                                        <h5 className="benefits_title_name">FishMart Franchise</h5>
                                        <p className="benefits_subtitle_name">Get Franchise Contact Our Team</p>
                                    </div>
                                </div>
                                <div className="benefits_inner" id="benefits_inner_right">
                                    <div className="benefits_img_div">
                                        <img className="benefits_img_big" src={discount} alt="delivery_van"></img>
                                    </div>
                                    <div className="benefits_content_div">
                                        <h5 className="benefits_title_name">Big Discount</h5>
                                        <p className="benefits_subtitle_name">Get everyday discount</p>
                                    </div>
                                </div>
                            </div>
                            <div className="benfits_bottom_border_div">
                                <div className="benefits_bottom_border"></div>
                            </div>
                        </section>

            {/* Hot Deals */}
            <section id="hotDeals">
              <HotDeals time={times} />
            </section>

            {/* Image Banner Content */}
            <section>
              <div className="image_banner_content_div">
                <div className="image_banner_content">
                  <div className="image_block_img_div">
                    <img
                      className="image_block_img"
                      src={two_men}
                      alt="fishing"
                    ></img>
                  </div>
                  <div className="image_main_banner_content">
                    <div>
                      <div className="image_main_relative">
                        <div>
                          <div
                            className="image_main_banner_content_div"
                            id="image_main_banner_centerContent"
                          >
                            <div id="image_main_padding_correction">
                              <h4 className="image_main_banner_title">
                                New Fishes
                              </h4>
                              <p className="image_main_banner_subtitle">
                                {" "}
                                SeaBoss Fishmart is your go-to destination for
                                premium seafood experiences.
                              </p>
                              <Link
                                to={"contactUs"}
                                smooth={true}
                                duration={500}
                              >
                                <button className="image_main_banner_btn">
                                  SHOP NOW
                                </button>
                              </Link>
                            </div>
                            <div className="image_main_absolute">
                              <div>
                                <span className="image_main_absolute_discount">
                                  50%
                                </span>
                                <h6 className="image_main_absolute_para">
                                  OFF
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="image_main_banner_content_div"
                          id="image_main_banner_style"
                        >
                          <img
                            className="image_main_banner_content"
                            src={bg_fish}
                            alt="fishing"
                          ></img>
                        </div>
                      </div>
                    </div>

                    <div className="image_main_desktop">
                      <div className="image_main_banner_content_div">
                        <img
                          className="image_main_banner_content"
                          src={man_fishing}
                          alt="fishing"
                        ></img>
                      </div>
                      <div className="image_main_relative">
                        <div
                          className="image_main_banner_content_div"
                          id="image_main_banner_centerContent"
                        >
                          <div id="image_main_padding_correction">
                            <h4 className="image_main_banner_title">
                              Featured Products
                            </h4>
                            <p className="image_main_banner_subtitle">
                              Discover the taste of the ocean with SeaBoss
                              Fishmart.
                            </p>
                            <Link to={"contactUs"} smooth={true} duration={500}>
                              <button className="image_main_banner_btn">
                                SHOP NOW
                              </button>
                            </Link>
                          </div>
                          <div className="image_main_absolute">
                            <div>
                              <span className="image_main_absolute_discount">
                                40%
                              </span>
                              <h6 className="image_main_absolute_para">OFF</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="image_main_mobile">
                      <div className="image_main_relative">
                        <div
                          className="image_main_banner_content_div"
                          id="image_main_banner_centerContent"
                        >
                          <div id="image_main_padding_correction">
                            <h4 className="image_main_banner_title">
                              Featured Products
                            </h4>
                            <p className="image_main_banner_subtitle">
                              Largest online fishing tackle shop in the UK and
                              Europe. Fishinggear is fishing
                            </p>
                            <Link to={"contactUs"} smooth={true} duration={500}>
                              <button className="image_main_banner_btn">
                                SHOP NOW
                              </button>
                            </Link>
                          </div>
                          <div className="image_main_absolute">
                            <div>
                              <span className="image_main_absolute_discount">
                                30%
                              </span>
                              <h6 className="image_main_absolute_para">OFF</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="image_main_banner_content_div">
                        <img
                          className="image_main_banner_content"
                          src={man_fishing}
                          alt="fishing"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Popular Products */}
            <section id="recommends">
              <PropularProduct />
            </section>

            {/* Trendy Collections */}
            <section>
              <div className="trendy_banner_div">
                <div className="trendy_banner_one">
                  <img src={banner1} alt="demoBanner"></img>
                  <div className="trendy_content_one">
                    <div>
                      <p className="trendy_content_title">Variety Fishes</p>
                      <h5 className="trendy_subContent_title">
                        Top Quality fishes
                      </h5>
                    </div>
                    <div>
                      <Link
                        to={"allProducts"}
                        smooth={true}
                        duration={500}
                        className="trendy_view_all_btn"
                      >
                        <div>
                          <span>VIEW COLLECTION</span>
                        </div>
                        <div>
                          <i
                            id="trendy_viewAll_arrow_icon"
                            class="fa-solid fa-arrow-right-long"
                          ></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="trendy_banner_two">
                  <img src={banner2} alt="demoBanner"></img>
                  <div className="trendy_content_two">
                    <div>
                      <p className="trendy_content_title">New Arrival</p>
                      <h5 className="trendy_subContent_title">
                        Salmon fishes 40% OFF
                      </h5>
                    </div>
                    <div>
                      <Link
                        to={"contactUs"}
                        smooth={true}
                        duration={500}
                        className="trendy_two_view_all_btn"
                      >
                        <div>
                          <span>SHOP NOW</span>
                        </div>
                        <div>
                          <i
                            id="trendy_viewAll_arrow_icon"
                            class="fa-solid fa-arrow-right-long"
                          ></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Top selling products */}
            <section id="allProducts">
              <div className="top_selling_overall">
                <div>
                  <h4 className="top_selling_title">Top Selling Products</h4>
                </div>
                <div className="top_selling_btns_div">
                  <div className="top_selling_btns_wrapper">
                    <div className="top_selling_btns">
                      <div>
                        <button
                          className={
                            btnValue === "seaFish"
                              ? "top_sell_btn_active"
                              : "top_sell_btn"
                          }
                          onClick={() => {
                            handleTopSellingBtn("seaFish");
                          }}
                        >
                          Sea Fish
                        </button>
                      </div>
                      <div>
                        <button
                          className={
                            btnValue === "freshWater"
                              ? "top_sell_btn_active"
                              : "top_sell_btn"
                          }
                          onClick={() => {
                            handleTopSellingBtn("freshWater");
                          }}
                        >
                          Fresh Water Fish
                        </button>
                      </div>
                      <div>
                        <button
                          className={
                            btnValue === "prawn"
                              ? "top_sell_btn_active"
                              : "top_sell_btn"
                          }
                          onClick={() => {
                            handleTopSellingBtn("prawn");
                          }}
                        >
                          Prawns
                        </button>
                      </div>
                      <div>
                        <button
                          className={
                            btnValue === "crab"
                              ? "top_sell_btn_active"
                              : "top_sell_btn"
                          }
                          onClick={() => {
                            handleTopSellingBtn("crab");
                          }}
                        >
                          Crab
                        </button>
                      </div>
                      <div>
                        <button
                          className={
                            btnValue === "squid"
                              ? "top_sell_btn_active"
                              : "top_sell_btn"
                          }
                          onClick={() => {
                            handleTopSellingBtn("squid");
                          }}
                        >
                          Squid
                        </button>
                      </div>
                      {/* <div>
                                                <button className={btnValue === "liveFish" ? "top_sell_btn_active" : "top_sell_btn"} onClick={() => { handleTopSellingBtn("liveFish") }}>
                                                    Live Fish
                                                </button>
                                            </div> */}
                      <div>
                        <button
                          className={
                            btnValue === "pickles_frozenItems"
                              ? "top_sell_btn_active"
                              : "top_sell_btn"
                          }
                          onClick={() => {
                            handleTopSellingBtn("pickles_frozenItems");
                          }}
                        >
                          Pickles & Masala Items
                        </button>
                      </div>
                      <div>
                        <button
                          className={
                            btnValue === "driedSeaFood"
                              ? "top_sell_btn_active"
                              : "top_sell_btn"
                          }
                          onClick={() => {
                            handleTopSellingBtn("driedSeaFood");
                          }}
                        >
                          Dried Seafish
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Products propsProductValue={btnValue} />

            {/* Comments section */}
            {/* <section>
                            <div className="comments_main_title_div" >
                                <h5 className="comments_main_title">Love From Clients</h5>
                                <h6 className="comments_main_subtitle">What our Clients says!</h6>
                            </div>
                            <div className="slider-container" id="cmt_section">
                                <Slider {...dotVisible}>
                                    <div>
                                        <div className="comment_section">
                                            <div className="comment_section_center">
                                                <div className="comment_section_imgDiv">
                                                    <div>
                                                        <img className="comment_section_img" src={cmt_1} alt="fishing_comment"></img>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="comment_section_para">I recently visited Seaboss Fishmart and was thoroughly impressed with the quality of the seafood they offer. Everything was incredibly fresh, from the fish to the shellfish. The staff was knowledgeable and helped me pick out the best selections for my meal. I also appreciated the clean and well-organized store layout. This franchise truly lives up to its promise of providing top-notch seafood. Highly recommend!</p>
                                                </div>
                                                <div>
                                                    <div>
                                                        <h6 className="comment_section_name">Sarah Thompson</h6>
                                                    </div>
                                                    <div>
                                                        <p className="comment_section_name_role">Sarah's Seafood Haven</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="comment_section">
                                            <div className="comment_section_center">
                                                <div className="comment_section_imgDiv">
                                                    <div>
                                                        <img className="comment_section_img" src={cmt_2} alt="fishing_comment"></img>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="comment_section_para">Seaboss Fishmart has become my go-to place for seafood. The variety and freshness of their products are unmatched. I particularly love their salmon and shrimp; you can tell they are of the highest quality. The service is always friendly and efficient, making the shopping experience pleasant every time. I’m so glad to have such a reliable source for fresh seafood in my neighborhood.</p>
                                                </div>
                                                <div>
                                                    <div>
                                                        <h6 className="comment_section_name">James Carter</h6>
                                                    </div>
                                                    <div>
                                                        <p className="comment_section_name_role">Carter's Catch</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="comment_section">
                                            <div className="comment_section_center">
                                                <div className="comment_section_imgDiv">
                                                    <div>
                                                        <img className="comment_section_img" src={cmt_3} alt="fishing_comment"></img>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="comment_section_para">As a seafood enthusiast, I was excited to check out Seaboss Fishmart, and it did not disappoint. The selection of fresh seafood is extensive, and the quality is exceptional. The staff is always ready to offer cooking tips and suggestions, which is a nice bonus. I've never been disappointed with my purchases here. If you're looking for a place that consistently provides fresh and quality seafood, Seaboss Fishmart is the place to go!</p>
                                                </div>
                                                <div>
                                                    <div>
                                                        <h6 className="comment_section_name">Emily Rodriguez</h6>
                                                    </div>
                                                    <div>
                                                        <p className="comment_section_name_role">Emily's Ocean Delights</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </section> */}

            {/* Double Banner */}
            <section>
              <div className="double_banner_div">
                <img
                  className="double_banner_img"
                  src={doubleBanner}
                  alt="doubleDemo"
                ></img>
                <img
                  className="double_banner_img_mobile"
                  src={doubleBannerMobile}
                  alt="doubleDemo"
                ></img>
                <div className="double_banner_contentFlex_div">
                  <div
                    className="double_banner_contentFlex"
                    id="double_banner_contentFlex"
                  >
                    <div>
                      <div className="double_content_one">
                        <div>
                          <p className="trendy_content_title">
                            New Products Added
                          </p>
                          <h5 className="trendy_subContent_title">
                            This Months Deals
                          </h5>
                        </div>
                        <div>
                          <Link
                            className="trendy_view_all_btn"
                            to={"allProducts"}
                            smooth={true}
                            duration={500}
                          >
                            <div>
                              <span> VIEW CATEGORIES</span>
                            </div>
                            <div>
                              <i
                                id="trendy_viewAll_arrow_icon"
                                class="fa-solid fa-arrow-right-long"
                              ></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="double_content_two">
                        <div>
                          <p className="trendy_content_title">NEW FISHES</p>
                          <h5 className="trendy_subContent_title">
                            50% SELLING OFF
                          </h5>
                        </div>
                        <div>
                          <Link
                            className="trendy_two_view_all_btn"
                            to={"contactUs"}
                            smooth={true}
                            duration={500}
                          >
                            <div>
                              <span>SHOP NOW</span>
                            </div>
                            <div>
                              <i
                                id="trendy_viewAll_arrow_icon"
                                class="fa-solid fa-arrow-right-long"
                              ></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Subscribe Letter */}
            <section id="contactUs">
              <div className="comments_main_title_div">
                <h5 className="comments_main_title">Contact Us</h5>
                <h6 className="comments_main_subtitle">Chat with Us!</h6>
              </div>
              <div className="subscribe_letter_overall">
                <div className="subscribe_letter_div">
                  <div className="subscribe_letter_div_one">
                    <div>
                      <i
                        id="subscribe_email"
                        class="fa-brands fa-square-whatsapp fa-2xl"
                      ></i>
                    </div>
                    <div className="subscribe_content">
                      <div>
                        <h6 className="subscribe_title">
                          Follow Our Whatsapp Channel
                        </h6>
                      </div>
                      <div>
                        <p className="subscribe_title_para">
                          Get all the latest information on Events, Sales and
                          Offers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <form
                    className="subscribe_letter_div_two"
                    onSubmit={handleChatSubmit}
                  >
                    <div>
                      <input
                        className="subscribe_input"
                        type="text"
                        required
                        onChange={(e) => {
                          setChatContent(e.target.value);
                        }}
                        placeholder="Drop us a message"
                      ></input>
                    </div>
                    <div>
                      <button type="submit" className="subscribe_btn">
                        SEND
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </section>

            {/* Footer Section */}
            <section>
              <div className="footer_center">
                <div>
                  <div className="footer_flex">
                    <div>
                      <div className="footer_title">
                        <h5 className="footer_title_content">ABOUT US</h5>
                      </div>
                      <div id="footer_innerPad">
                        <div>
                          <p className="footer_aboutUs">
                            {" "}
                            SeaBoss Fishmart is a leading seafood company
                            dedicated to providing customers with the freshest
                            and highest quality fish and seafood products. With
                            a commitment to sourcing sustainably and
                            responsibly, SeaBoss Fishmart offers a diverse
                            selection of seafood sourced from trusted suppliers
                            and local fishermen.
                          </p>
                        </div>
                        <div className="footer_social_links_overall">
                          <div>
                            <button
                              onClick={handleFb}
                              className="footer_social_link"
                            >
                              <i
                                id="footer_social_link_icon"
                                class="fa-brands fa-facebook-f"
                              ></i>
                            </button>
                          </div>
                          <div>
                            <button
                              onClick={handleInsta}
                              className="footer_social_link"
                              id="footer_social_link"
                            >
                              <i
                                id="footer_social_link_icon"
                                class="fa-brands fa-instagram"
                              ></i>
                            </button>
                          </div>
                          <div>
                            <button
                              onClick={handleYoutube}
                              className="footer_social_link"
                              id="footer_social_link"
                            >
                              <i
                                id="footer_social_link_icon"
                                class="fa-brands fa-youtube"
                              ></i>
                            </button>
                          </div>
                          {/* <div>
                                                        <button className="footer_social_link" id="footer_social_link">
                                                            <i id="footer_social_link_icon" class="fa-brands fa-x-twitter"></i>
                                                        </button>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div>
                                                <hr className="footer_seperate_mobile"></hr>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="footer_title">
                                                <h5 className="footer_title_content">QUICK LINKS</h5>
                                            </div>
                                            <div className="footer_quick_linksDiv" id="footer_innerPad">
                                                <div>
                                                    <Link className="footer_quick_links" to={"header"} smooth={true} duration={500}>Home</Link>
                                                </div>
                                                <div>
                                                    <Link className="footer_quick_links" to={"benefits"} smooth={true} duration={500}>Benefits</Link>
                                                </div>
                                                <div>
                                                    <Link className="footer_quick_links" to={"hotDeals"} smooth={true} duration={500}>Hot Deals</Link>
                                                </div>
                                                <div>
                                                    <Link className="footer_quick_links" to={"recommends"} smooth={true} duration={500}>Recommends</Link>
                                                </div>
                                                <div>
                                                    <Link className="footer_quick_links" to={"allProducts"} smooth={true} duration={500}>All Products</Link>
                                                </div>
                                                <div>
                                                    <Link className="footer_quick_links" to={"contactUs"} smooth={true} duration={500}>Contact Us</Link>
                                                </div>
                                            </div>
                                            <div>
                                                <hr className="footer_seperate_mobile"></hr>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="footer_title">
                                                <h5 className="footer_title_content">ADDRESS</h5>
                                            </div>
                                            <div id="footer_innerPad">
                                                <div>
                                                    <div >
                                                        <div>
                                                            <address className="footer_addressContent">
                                                                NO.380/9Th, Main 23RD Cross
                                                                7Th Sector, HSR Layout,
                                                                Bangalore-560102.
                                                            </address>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <hr className="footer_seperate"></hr>
                                                    </div>
                                                    <div className="footer_phoneNumber_overall">
                                                        <div>
                                                            <h6 className="footer_title_content">PHONE NUMBER</h6>
                                                        </div>
                                                        <div className="footer_phone_numDiv">
                                                            <h6 className="footer_phone_num">+91 8867906289</h6>
                                                            <h6 className="footer_phone_num">+91 8867906287</h6>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <hr className="footer_seperate"></hr>
                                                    </div>
                                                    <div className="footer_email_content">
                                                        <h6 className="footer_title_content">EMAIL</h6>
                                                        <div>
                                                            <span>franchisee@seaboss.in</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <hr className="footer_seperate_mobile"></hr>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="footer_title">
                                                <h5 className="footer_title_content">MAP</h5>
                                            </div>
                                            <div id="footer_innerPad" className="footer_map_container">
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.0082629604753!2d77.63359788656128!3d12.907190020324972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae155c2efbe7fd%3A0xe89890424ad16129!2sSeaboss%20Fish%20mart!5e0!3m2!1sen!2sin!4v1719053557199!5m2!1sen!2sin" className="mapStyles" allowfullscreen="" loading="lazy" title="seaboss_location" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </div>
                                        <div className="footer_franchises">
                                            <button className="franchise_button" onClick={handleFranchises}>FishFries Franchises Available!</button> 
                                        </div>
                                    </div>

                  <div className="footer_hr_line"></div>

                  <div className="footer_copyright">
                    <h6 className="footer_copyright_content">
                      Copyright © Seaboss FishMart 2024 . ALL Right Reserved .
                    </h6>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
