import React from 'react';
import header_page_bg from "../../assests/user/images/Header_Navigation_img/fishmart2.jpg";
import "../../components/styles/user/Header_Navigation.css";


export const HeaderNavigation = ({data}) => {
    return (
        <div className="navigation_overall">
            <img className="navigation_bg_img" src={header_page_bg} alt="pagination"></img>
            <div className="navigation_bg_content">
                <div>
                    <h1 className="navigation_title">{data.headerTitle}</h1>
                </div>
                <div className="navigation_para_div">
                    <span>{data.defaultPage}</span>
                    <span id="navigation_span_arrow">⮞⮞</span>
                    <span>{data.currentPage}</span>
                </div>
            </div>
        </div>
    )
};

